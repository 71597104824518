/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import { validateAll } from "indicative/validator";
import {
    CustomErrorToast,
    InfoToast,
    SuccessToast,
} from "../../../components/blocks/Alert";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import { DrawerButton } from "../../../components/elements/Button";
import Loading from "../../../components/elements/Loading/Loading";
import { assignTerminalStaffApi } from "../../../redux/slice/staff/staff.api";
import { nigerianStates } from "../../../lib/data/nigerian_states";

type Props = {
    open: boolean;
    handleClose: () => void;
    staff: any;
    terminalIds: string[];
};

const AssignTerminalModal = ({
    open,
    handleClose,
    staff,
    terminalIds,
}: Props) => {
    const [formData, setFormData] = useState<any>({
        staffUserId: "",
        state: "",
        lga: "",
        terminals: terminalIds,
    });

    const [lgas, setLgas] = useState<string[]>([]);

    const logData = staff.map((user: any) => ({
        name: `${user.first_name} ${user.last_name}`,
        value: user.userId,
    }));

    const [error, setError] = useState<any>("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === "state") {
            const selectedState = nigerianStates.find(
                state => state.name === value
            );
            setLgas(selectedState ? selectedState.lgas : []);
            setFormData((prev: any) => ({ ...prev, lga: "" })); // Reset LGA when state changes
        }
    };

    const processCreate = async (data: any) => {
        setLoading(true);
        try {
            const { terminals, state, lga, ...restData } = data;

            // Initialize assignmentCriteria with terminalIds
            const assignmentCriteria: any = {
                terminalIds: data.terminals,
            };

            // Add state and lga to assignmentCriteria only if they are selected
            if (state) {
                assignmentCriteria.state = state;
            }
            if (lga) {
                assignmentCriteria.lga = lga;
            }

            // Construct the final formattedData object
            const formattedData = {
                ...restData,
                assignmentCriteria,
            };

            console.log("Assign Data: ", formattedData);

            // Call the API
            const res = await assignTerminalStaffApi(formattedData);
            if (res.successful) SuccessToast("Task assigned successfully");
            if (!res.successful) InfoToast(res.message);
            setLoading(false);
            handleClose();
        } catch (err: any) {
            setLoading(false);
            CustomErrorToast(err);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            staffUserId: "required|string",
        };

        const messages = {
            "staffUserId.required": "Assigned person is required",
            "staffUserId.string": "Assigned person must be a valid string",
        };

        validateAll(formData, rules, messages)
            .then(() => processCreate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    useEffect(() => {
        if (staff.length < 1) return;
        console.log("Staff: ", staff);
    }, [staff]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Create & Assign Tas to a Staff
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={logData}
                        label="Assigned To"
                        onChange={handleInputChange}
                        name="staffUserId"
                        value={formData.staffUserId}
                        error={error.staffUserId}
                    />
                </div>

                <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={nigerianStates.map(state => ({
                            name: state.name,
                            value: state.name,
                        }))}
                        label="State"
                        onChange={handleInputChange}
                        name="state"
                        value={formData.state}
                    />
                </div>

                <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={lgas.map(lga => ({ name: lga, value: lga }))}
                        label="LGA"
                        onChange={handleInputChange}
                        name="lga"
                        value={formData.lga}
                    />
                </div>

                <div className="flex flex-col justify-center">
                    <DrawerButton onClick={handleSubmit}>
                        {loading ? <Loading size="sm" /> : "Assign Terminal"}
                    </DrawerButton>
                    <Button
                        onClick={handleClose}
                        className="text-red-600 bg-slate-100 hover:text-red-600"
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AssignTerminalModal;
