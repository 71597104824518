import axios from "../../../axios";

export const getAllTagsApi = async () => {
    try {
        const url = "/tags";

        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const createTagsApi = async (payload: any) => {
    try {
        const url = "/tags";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const assignTagsApi = async (payload: any) => {
    try {
        const url = "/tags/assign";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const deAssignUserTagApi = async (payload: any) => {
    try {
        const url = "/tags/remove-from-users";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const assignTerminalTagsApi = async (payload: any) => {
    try {
        const url = "/terminals/staffassign";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const deAssignTagsApi = async (payload: any) => {
    try {
        const url = "/lolastaffs/deassign";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const deleteTagsApi = async (lolaStaffUserId: string) => {
    try {
        const url = `/lolastaffs/${lolaStaffUserId}`;
        const res = await axios.delete(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};
