import React from "react";
import {
    FaUsers,
    FaBuilding,
    FaUserCog,
    FaIdCardAlt,
    FaWarehouse,
} from "react-icons/fa";

import { MdMonitor, MdSpaceDashboard } from "react-icons/md";
import { AiOutlineTransaction, AiTwotoneCalculator } from "react-icons/ai";
import { GoFileSubmodule } from "react-icons/go";
import { GiTeamIdea } from "react-icons/gi";

interface MenuItemBase {
    id: number;
    name: string;
    icon: React.ReactNode;
}

interface MenuItemWithUrl extends MenuItemBase {
    url: string;
    category?: never;
    subItems?: never;
}

interface MenuItemWithSubItems extends MenuItemBase {
    url?: never;
    category: string;
    subItems: { id: number; name: string; url: string }[];
}

type MenuItem = MenuItemWithUrl | MenuItemWithSubItems;

export const adminNav: MenuItem[] = [];

export const staffNav: MenuItem[] = [
    {
        id: 1,
        name: "Dashboard",
        url: "/dashboard",
        icon: <MdSpaceDashboard className="size-6" />,
    },
    {
        id: 2,
        name: "Customers",
        url: "/users",
        icon: <FaUsers className="size-6" />,
    },
    {
        id: 7,
        name: "Transactions",
        url: "/transactions",
        icon: <AiOutlineTransaction className="size-6" />,
    },
    {
        id: 3,
        name: "POS Agents",
        url: "/pos-agents",
        icon: <FaIdCardAlt className="size-6" />,
    },
    {
        id: 11,
        name: "POS Terminals",
        url: "/pos-terminals",
        icon: <AiTwotoneCalculator className="size-6" />,
    },
    {
        id: 4,
        name: "Communities",
        url: "/communities",
        icon: <FaBuilding className="size-6" />,
    },
    {
        id: 5,
        name: "Staff",
        url: "/staff",
        icon: <FaUserCog className="size-6" />,
    },
    {
        id: 6,
        name: "Reports",
        icon: <GoFileSubmodule className="size-6" />,
        category: "reports",
        subItems: [
            { id: 1, name: "Onboarders Report", url: "/reports" },
            {
                id: 2,
                name: "Community Calls Report",
                url: "/community-report",
            },
        ],
    },
    {
        id: 8,
        name: "Teams",
        url: "/teams",
        icon: <GiTeamIdea className="size-6" />,
    },
    {
        id: 9,
        name: "Workspaces",
        url: "/workspaces",
        icon: <FaWarehouse className="size-6" />,
    },
    {
        id: 10,
        name: "Monitor",
        url: "/monitor",
        icon: <MdMonitor className="size-6" />,
    },
];
export const guestNav: MenuItem[] = [
    {
        id: 1,
        name: "Dashboard",
        url: "/dashboard",
        icon: <MdSpaceDashboard className="size-6" />,
    },
    {
        id: 10,
        name: "Monitor",
        url: "/monitor",
        icon: <MdMonitor className="size-6" />,
    },
];
