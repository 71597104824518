/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import AssignTerminalModal from "./AssignTerminalFormModal";
import { getAllStaffApi } from "../../../redux/slice/staff/staff.api";
import Loading from "../../../components/elements/Loading/Loading";
// import AssignTerminalModal from "./AssignTerminalFormModal";

interface iProp {
    terminalIds: string[];
}

const AddStaffTerminal = ({ terminalIds }: iProp) => {
    const [addStaff, setAddStaff] = useState(false);
    const [logData, setLogData] = useState<any[]>([]);

    const [pagination] = useState({
        perPage: 15,
        page: 1,
    });

    const [loading, setLoading] = useState<boolean>(false);

    const [staff, setStaff] = useState([]);

    const fetchStaff = async (
        pageLimit?: number,
        // pageNumber?: number,
        query?: any
    ) => {
        try {
            setLoading(true);
            const res = await getAllStaffApi(
                pageLimit || pagination.perPage,
                pagination.page,
                query
            );
            // console.log("staff", res);
            setStaff(res.staffsResultSet);
            return res;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStaff();
    }, []);

    useEffect(() => {
        if (staff.length < 1) return;
        const logs = staff.map((user: any) => ({
            first_name: user.userRecord.first_name,
            last_name: user.userRecord.last_name,
            userId: user.userId,
        }));
        setLogData(logs);
        console.log(logs);
    }, [staff]);

    return (
        <>
            <Button
                appearance="primary"
                className="bg-[#006399] py-1.5 px-4 text-white w-full max-w-[180px] hover:bg-[#0073b3] rounded"
                onClick={() => setAddStaff(true)}
                disabled={loading}
            >
                {loading ? <Loading size="xs" /> : "Assign to Staff"}
            </Button>

            {addStaff && (
                <AssignTerminalModal
                    terminalIds={terminalIds}
                    staff={logData}
                    open
                    handleClose={() => setAddStaff(false)}
                />
            )}
        </>
    );
};

export default AddStaffTerminal;
