/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */

import React, { FormEvent, useEffect, useState } from "react";
import { Button, Modal, Form, TagPicker, Loader } from "rsuite";
import { Plus } from "lucide-react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import {
    addUsersToBucketApi,
    getBucketDetailsApi,
} from "../../../redux/slice/monitor/monitor.api";
import { ErrorToast, SuccessToast } from "../../../components/blocks/Alert";
import BulkAddUser from "./BulkAddUser";
import EditBucket from "./EditBucket";
import WorkspaceModal from "./WorkspaceModal";
import Tags from "../../../components/blocks/Tags";
import DynamicFilter from "./DynamicFilterButton";
import DeAssignTags from "../../../components/blocks/Tags/DeAssign";

interface Tag {
    _id: string;
    label: string;
    color: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

interface Account {
    id: string;
    name: string;
    phone: string;
    date_created: string;
    dailyTransactions: number[];
    hasTransacted: boolean;
    total: number;
    tags: Tag[]; // Add tags as an array of Tag objects
}

const ActivityPage = () => {
    const navigate = useNavigate();
    const { bucketId } = useParams();
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [bucketDetails, setBucketDetails] = useState<any | null>(null);
    const [activityDetails, setActivityDetails] = useState<any | null>(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newAccount, setNewAccount] = useState<{ phoneNumbers: string[] }>({
        phoneNumbers: [],
    });
    const [failedAccounts, setFailedAccounts] = useState<
        { phoneNumber: string; reason: string }[]
    >([]);
    const [addedAccounts, setAddedAccounts] = useState<string[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [rowClicked, setRowClicked] = useState<Account | null>(null);
    const [filter, setFilter] = useState<
        "all" | "hasTransacted" | "hasNotTransacted"
    >("all");
    const [selectedRows, setSelectedRows] = useState<string[]>([]); // State to store selected row IDs
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]); // State to store selected Tags
    const [filteredAccounts, setFilteredAccounts] = useState<Account[]>([]); // State to store filtered accounts

    // Calculate counts for each filter
    const allAccountsCount = accounts.length;
    const hasTransactedCount = accounts.filter(
        account => account.hasTransacted
    ).length;
    const hasNotTransactedCount = accounts.filter(
        account => !account.hasTransacted
    ).length;

    const handleReturn = () => {
        navigate("/monitor");
    };

    const handleRowClicked = (row: any) => {
        setRowClicked(row);
    };

    const handleModalClose = () => {
        setRowClicked(null);
    };

    const handleSubmit = async (
        checkStatus: boolean,
        e: FormEvent<HTMLFormElement>
    ) => {
        e.preventDefault();

        if (!bucketId || newAccount.phoneNumbers.length < 1) return;

        setLoading(true);
        setFailedAccounts([]);
        setAddedAccounts([]);

        try {
            const payload = {
                bucketId,
                phoneNumbers: newAccount.phoneNumbers,
            };
            const response = await addUsersToBucketApi(
                bucketId,
                payload.phoneNumbers
            );

            const { added, failed } = response.data.data;

            if (failed.length > 0) {
                setFailedAccounts(failed);
            }

            if (added.length > 0) {
                setAddedAccounts(added);
                SuccessToast(`${added.length} User(s) added successfully`);
            } else if (failed.length === 0) {
                SuccessToast("No changes made.");
            }
        } catch (error) {
            ErrorToast("Couldn't Complete... Something Went Wrong");
            console.error("Validation error:", error);
        } finally {
            setLoading(false);
        }

        setOpen(false);
    };

    const fetchBucketDetails = async () => {
        try {
            if (!bucketId) return;

            setIsFetching(true);
            const { data } = await getBucketDetailsApi(bucketId);
            setBucketDetails(data.data.bucket);
            setActivityDetails(data.data.bucket.activity);

            const transformedAccounts = data.data.data.map((entry: any) => ({
                id: entry.accountReference,
                name: entry.accountName || "N/A",
                phone: entry.phoneNumber || "N/A",
                date_created: new Date(
                    bucketDetails?.createdAt || Date.now()
                ).toLocaleDateString(),
                dailyTransactions: entry.dailyCounts.map(
                    (count: { count: number }) => count.count
                ),
                total: entry.totalCount,
                hasTransacted: entry.hasTransacted,
                tags: entry.tags,
            }));
            setAccounts(transformedAccounts);
        } catch (error) {
            console.error("Error fetching buckets:", error);
        } finally {
            setIsFetching(false);
        }
    };

    const handleFilterSubmit = (filteredData: any) => {
        console.log("Filtered Data:", filteredData);
        // Handle the filtered data (e.g., update state or display results)
    };

    const updateToggle = (
        filterType: "all" | "hasTransacted" | "hasNotTransacted"
    ) => {
        setFilter(filterType);
    };

    // Recalculate filteredAccounts whenever filter or accounts change
    useEffect(() => {
        const filtered = accounts.filter(account => {
            if (filter === "hasTransacted") {
                return account.hasTransacted;
            }
            if (filter === "hasNotTransacted") {
                return !account.hasTransacted;
            }
            return true; // Return all accounts if filter is "all"
        });
        setFilteredAccounts(filtered);
    }, [filter, accounts]);

    // Handle row selection
    const handleRowSelection = (id: string) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter(rowId => rowId !== id)); // Deselect
        } else {
            setSelectedRows([...selectedRows, id]); // Select
        }
    };

    // Handle tag selection
    const handleTagSelection = (account: Account) => {
        setSelectedTags(account.tags);
    };

    // Handle select all rows
    const handleSelectAll = () => {
        if (selectedRows.length === filteredAccounts.length) {
            setSelectedRows([]); // Deselect all
        } else {
            setSelectedRows(filteredAccounts.map((account: any) => account.id)); // Select all
        }
    };

    useEffect(() => {
        fetchBucketDetails();
    }, [bucketId]);

    return (
        <div className="container mx-auto relative">
            <div className="flex flex-col gap-4">
                <Button
                    onClick={handleReturn}
                    appearance="ghost"
                    className="rounded-full flex items-center w-fit hover:bg-gray-100"
                >
                    <BiChevronLeft />
                    Go Back
                </Button>

                {/* Skeleton Loading for Bucket Details */}
                {isFetching ? (
                    <div className="mb-4 animate-pulse">
                        <div className="h-8 bg-gray-200 rounded w-1/2 mb-2" />
                        <div className="h-4 bg-gray-200 rounded w-1/3 mb-2" />
                        <div className="h-4 bg-gray-200 rounded w-1/3" />
                    </div>
                ) : (
                    bucketDetails && (
                        <div className="flex justify-between flex-wrap gap-4">
                            <div className="mb-4">
                                <p className="text-xl font-semibold mb-2">
                                    {bucketDetails.bucketName}
                                </p>
                                <p className="text-gray-500 m-0">
                                    <strong>Monitoring Period:</strong>{" "}
                                    {bucketDetails.monitoringPeriod} days
                                </p>
                                <p className="text-gray-500 m-0">
                                    <strong>Created At:</strong>{" "}
                                    {new Date(
                                        bucketDetails.createdAt
                                    ).toLocaleString()}
                                </p>
                            </div>
                            <EditBucket
                                bucketId={bucketId}
                                bucketName={bucketDetails.bucketName}
                            />
                        </div>
                    )
                )}

                {/* Skeleton Loading for Activity Details */}
                {isFetching ? (
                    <div className="border bg-gray-50 p-4 mb-4 rounded-lg animate-pulse">
                        <div className="h-6 bg-gray-200 rounded w-1/4 mb-4" />
                        <div className="h-4 bg-gray-200 rounded w-1/2 mb-2" />
                        <div className="h-4 bg-gray-200 rounded w-1/3 mb-2" />
                        <div className="h-4 bg-gray-200 rounded w-1/4" />
                    </div>
                ) : (
                    activityDetails && (
                        <div className="border bg-gray-50 p-4 mb-4 rounded-lg">
                            <h4 className="text-xl font-semibold mb-4">
                                Activity Details
                            </h4>
                            <p>
                                <strong>Activity Name:</strong>{" "}
                                {activityDetails.activityName}
                            </p>
                            <p>
                                <strong>Description:</strong>{" "}
                                {activityDetails.description}
                            </p>
                            <p>
                                <strong>Data Type:</strong>{" "}
                                {activityDetails.dataType}
                            </p>
                        </div>
                    )
                )}

                {/* Display Failed Accounts */}
                {failedAccounts.length > 0 && (
                    <div className="mt-6 bg-red-50 rounded-lg p-4">
                        <h4 className="text-lg font-semibold text-red-600 mb-4">
                            Failed Accounts
                        </h4>
                        <ul className="list-disc pl-6">
                            {failedAccounts.map(entry => (
                                <li
                                    key={entry.phoneNumber}
                                    className="text-red-500"
                                >
                                    {entry.phoneNumber} - {entry.reason}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {/* Display Added Accounts */}
                {addedAccounts.length > 0 && (
                    <div className="mt-6 bg-green-50 rounded-lg p-4">
                        <h4 className="text-lg font-semibold text-green-600 mb-4">
                            Added Accounts
                        </h4>
                        <ul className="list-disc pl-6">
                            {addedAccounts.map((phoneNumber, index) => (
                                <li
                                    key={phoneNumber}
                                    className="text-green-500"
                                >
                                    {phoneNumber}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className="flex flex-wrap-reverse gap-4 justify-end items-center sticky top-40 md:top-16 py-8 bg-white">
                    <BulkAddUser bucketId={bucketId} />
                    <Button
                        onClick={() => {
                            setOpen(true);
                            setNewAccount({ phoneNumbers: [] });
                        }}
                        appearance="primary"
                        className="flex items-center bg-blue-400"
                    >
                        <Plus className="mr-2 h-4 w-4" /> Add Account
                    </Button>
                    {selectedRows.length > 0 && (
                        <Tags
                            bucketId={bucketId}
                            bucketUserWalletKeys={selectedRows}
                            handleModalClose={fetchBucketDetails}
                        />
                    )}

                    {selectedTags.length > 0 &&
                        selectedRows.length > 0 &&
                        selectedRows.length < 2 && (
                            <DeAssignTags
                                bucketId={bucketId}
                                bucketUserWalletKeys={selectedRows}
                                handleModalClose={fetchBucketDetails}
                                tags={selectedTags}
                            />
                        )}

                    <DynamicFilter
                        items={
                            selectedRows.length > 0
                                ? selectedRows
                                : filteredAccounts.map(
                                      (account: any) => account.id
                                  )
                        }
                        onFilterSubmit={handleFilterSubmit}
                    />
                </div>

                {/* Skeleton Loading for Table */}
                {isFetching ? (
                    <div className="overflow-x-auto flex flex-col animate-pulse">
                        <table className="table-auto border border-gray-300 min-w-[760px] w-full">
                            <thead className="w-full">
                                <tr className="bg-gray-100 w-full">
                                    {[...Array(5)].map((_, index) => (
                                        <th
                                            key={index}
                                            className="border border-gray-300 px-4 py-2"
                                        >
                                            <div className="h-4 bg-gray-200 rounded" />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="w-full">
                                {[...Array(5)].map((_, index) => (
                                    <tr
                                        key={index}
                                        className="hover:bg-gray-50"
                                    >
                                        {[...Array(5)].map((_a, i) => (
                                            <td
                                                key={i}
                                                className="border border-gray-300 px-4 py-2"
                                            >
                                                <div className="h-4 bg-gray-200 rounded" />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <>
                        <div className="flex items-end gap-4">
                            <span
                                className={
                                    `flex gap-3 px-2` && filter === "all"
                                        ? `pb-2 border-b-2 border-b-[#F75500] font-avenirBook font-extrabold text-[#101828] cursor-pointer`
                                        : `text-[#667085]`
                                }
                                onClick={() => updateToggle("all")}
                                role="button"
                            >
                                All
                                <span className="p-1 w-4 h-4 shrink-0 mx-2 rounded-full bg-orange-100">
                                    {allAccountsCount}
                                </span>
                            </span>
                            <span
                                className={
                                    `flex gap-3 px-2` &&
                                    filter === "hasTransacted"
                                        ? `pb-2 border-b-2 border-b-[#F75500] font-avenirBook font-extrabold text-[#101828] cursor-pointer`
                                        : `text-[#667085]`
                                }
                                onClick={() => updateToggle("hasTransacted")}
                                role="button"
                            >
                                Has Transacted
                                <span className="p-1 w-4 h-4 shrink-0 mx-2 rounded-full bg-orange-100">
                                    {hasTransactedCount}
                                </span>
                            </span>
                            <span
                                className={
                                    `flex gap-3 px-2` &&
                                    filter === "hasNotTransacted"
                                        ? `pb-2 border-b-2 border-b-[#F75500] font-avenirBook font-extrabold text-[#101828] cursor-pointer`
                                        : `text-[#667085]`
                                }
                                onClick={() => updateToggle("hasNotTransacted")}
                                role="button"
                            >
                                Has Not Transacted
                                <span className="p-1 w-4 h-4 shrink-0 mx-2 rounded-full bg-orange-100">
                                    {hasNotTransactedCount}
                                </span>
                            </span>
                        </div>
                        <div className="overflow-x-auto flex flex-col">
                            <table className="table-auto border border-gray-300 min-w-[760px] w-full">
                                <thead className="w-full">
                                    <tr className="bg-gray-100 w-full">
                                        <th className="border border-gray-300 px-4 py-2">
                                            <input
                                                type="checkbox"
                                                checked={
                                                    selectedRows.length ===
                                                    filteredAccounts.length
                                                }
                                                onChange={handleSelectAll}
                                            />
                                        </th>
                                        <th className="border border-gray-300 px-4 py-2 max-w-max truncate">
                                            Name
                                        </th>
                                        <th className="border border-gray-300 px-4 py-2 truncate">
                                            Phone
                                        </th>
                                        <th className="border border-gray-300 px-4 py-2 truncate">
                                            Date Created
                                        </th>
                                        <th className="border border-gray-300 px-4 py-2 truncate">
                                            Tags
                                        </th>
                                        {activityDetails?.dataType ===
                                            "time_series" && (
                                            <th className="border border-gray-300 px-4 py-2 flex-auto max-w-md">
                                                Monitory Days
                                            </th>
                                        )}
                                        <th className="border border-gray-300 px-4 py-2 text-right truncate">
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="w-full">
                                    {filteredAccounts.map((account, index) => (
                                        <tr
                                            key={index}
                                            onDoubleClick={() =>
                                                handleRowClicked(account)
                                            }
                                            onClick={() => {
                                                handleRowSelection(account.id);
                                                handleTagSelection(account);
                                            }}
                                            className={`cursor-pointer select-none ${
                                                selectedRows.includes(
                                                    account.id
                                                )
                                                    ? "bg-blue-100 hover:bg-blue-200 "
                                                    : "hover:bg-gray-50 "
                                            }`}
                                        >
                                            <td className="border border-gray-300 px-4 py-2">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.includes(
                                                        account.id
                                                    )}
                                                    onChange={() =>
                                                        handleRowSelection(
                                                            account.id
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 max-w-max truncate">
                                                {account.name}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 truncate">
                                                {account.phone}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 truncate">
                                                {account.date_created}
                                            </td>
                                            {/* New cell for tags */}
                                            <td className="border border-gray-300 px-4 py-2">
                                                <div className="flex flex-wrap gap-2">
                                                    {account.tags &&
                                                    account.tags.length > 0 ? (
                                                        account.tags.map(
                                                            tag => (
                                                                <span
                                                                    key={
                                                                        tag._id
                                                                    }
                                                                    className="inline-flex items-center font-semibold px-2 py-1 rounded-full text-xs font-medium"
                                                                    style={{
                                                                        backgroundColor:
                                                                            tag.color,
                                                                    }}
                                                                >
                                                                    {tag.label}
                                                                </span>
                                                            )
                                                        )
                                                    ) : (
                                                        <div className="">
                                                            No Tags
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                            {activityDetails?.dataType ===
                                                "time_series" && (
                                                <td className="border border-gray-300 grow max-w-md overflow-x-auto">
                                                    <div className="overflow-x-auto flex justify-start">
                                                        {Array.from(
                                                            {
                                                                length:
                                                                    bucketDetails?.monitoringPeriod ??
                                                                    7,
                                                            },
                                                            (_, i) => (
                                                                <div
                                                                    key={i}
                                                                    className="flex flex-col whitespace-nowrap grow border divide-y"
                                                                >
                                                                    <div className="p-2 text-xs">
                                                                        Day{" "}
                                                                        {i + 1}
                                                                    </div>
                                                                    <div className="text-center font-semibold p-1">
                                                                        {account
                                                                            .dailyTransactions[
                                                                            i
                                                                        ] ??
                                                                            "N/A"}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </td>
                                            )}
                                            <td className="border border-gray-300 px-4 py-2 font-semibold text-right">
                                                {account.total}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>

            <Modal open={open} onClose={() => setOpen(false)} size="sm">
                <Modal.Header>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.ControlLabel>Phone Numbers</Form.ControlLabel>
                            <TagPicker
                                data={newAccount.phoneNumbers.map(phone => ({
                                    label: phone,
                                    value: phone,
                                }))}
                                creatable
                                placeholder="Enter phone numbers"
                                value={newAccount.phoneNumbers}
                                onChange={value =>
                                    setNewAccount({
                                        ...newAccount,
                                        phoneNumbers: value,
                                    })
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <Button
                                appearance="primary"
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-400 text-white min-w-[100px]"
                                disabled={loading}
                            >
                                {loading ? <Loader /> : "Add Account"}
                            </Button>
                            <Button
                                onClick={() => setOpen(false)}
                                appearance="ghost"
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>

            {rowClicked !== null && (
                <WorkspaceModal
                    specificCustomerPhone={rowClicked.phone}
                    open={rowClicked !== null}
                    handleClose={handleModalClose}
                />
            )}
        </div>
    );
};

export default ActivityPage;
