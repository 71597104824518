/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, InputPicker, Loader } from "rsuite";
import {
    deAssignUserTagApi,
    getAllTagsApi,
} from "../../../../redux/slice/tags/tags.api";
import { ErrorToast, SuccessToast } from "../../Alert";

interface Tag {
    type: string;
    _id: string;
    label: string;
    color: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

type Props = {
    open: boolean;
    props: any;
    handleClose: () => void;
    tags: Tag[];
};

const DeAssignTagsModal = ({ open, props, handleClose, tags }: Props) => {
    // const [tags, setTags] = useState<Tag[]>([]);
    const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
    const [deAssignLoading, setDeAssignLoading] = useState<boolean>(false);
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);

    const handleTagSelection = (tagId: string) => {
        setSelectedTagId((prevSelected: any) => {
            if (prevSelected === tagId) {
                return null;
            }
            return tagId;
        });
    };

    const handleDeAssignTag = async () => {
        if (!selectedTagId) return;

        try {
            setDeAssignLoading(true);
            await deAssignUserTagApi({ ...props, tagId: selectedTagId });
            SuccessToast("Tag de-assigned successfully!");
            handleClose();
        } catch (err) {
            ErrorToast("An error occurred while de-assigning the tag.");
        } finally {
            setDeAssignLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="sm"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    De-Assign Tag
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="flex flex-col gap-4">
                    <p className="text-sm text-gray-600">
                        Select a tag to de-assign from this item:
                    </p>

                    <div className="flex flex-row flex-wrap w-full gap-2 pb-6 border-b">
                        {fetchLoading ? (
                            [...Array(3)].map((_, index) => (
                                <div
                                    key={index}
                                    className="p-2 min-w-[160px] max-w-xs border rounded-lg bg-white border-gray-200 flex flex-row gap-2 animate-pulse"
                                >
                                    <div className="w-5 h-5 bg-gray-200 rounded-full" />
                                    <div className="flex flex-col flex-1">
                                        <div className="h-4 bg-gray-200 rounded w-3/4 mb-1" />
                                    </div>
                                </div>
                            ))
                        ) : tags.length === 0 ? (
                            <p className="text-center text-gray-500 bg-slate-50 rounded p-2 w-full">
                                No assigned tags to de-assign.
                            </p>
                        ) : (
                            tags.map(tag => (
                                <div
                                    role="button"
                                    key={tag._id}
                                    className={`p-2 min-w-[160px] max-w-xs border rounded-lg bg-white border-gray-200 flex flex-row gap-2 cursor-pointer ${
                                        selectedTagId === tag._id
                                            ? "border-red-500 bg-red-50" // Changed to red for de-assign
                                            : ""
                                    }`}
                                    onClick={() => handleTagSelection(tag._id)}
                                >
                                    <div
                                        className="w-5 h-5 rounded-full"
                                        style={{ backgroundColor: tag.color }}
                                    />
                                    <div className="flex flex-col">
                                        <p className="text-sm font-medium">
                                            {tag.label}
                                        </p>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                    {selectedTagId !== null && (
                        <Button
                            type="button"
                            appearance="default"
                            onClick={handleDeAssignTag}
                            className="w-full !bg-red-500 hover:!bg-red-600 !text-white"
                        >
                            {deAssignLoading ? (
                                <Loader />
                            ) : (
                                "De-Assign Selected Tag"
                            )}
                        </Button>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeAssignTagsModal;
