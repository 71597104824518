/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import capitalizeFistLetterInSentence from "../../../utils/capitalizeFirstLetter";
import numberFormat from "../../../utils/numberFormat";
import formatDate from "../../../utils/formatDate";
import { getTransactionsApi } from "../../../redux/slice/transactions/transactions.api";
import TableSubHeader from "../TableSubHeader";
import Loading from "../../elements/Loading/Loading";
import PaginationFilter from "../../elements/paginationFilter";
import FilterByDate from "../../../pages/appcore/dashboard/filterModals/FilterByDate";
import TransactionDetail from "../../../pages/appcore/dashboard/TransactionDetail";
import CustomLoader from "../../elements/Loading/CustomLoader";

const columns = [
    {
        name: "Description",
        selector: (row: any) => row.description,
    },
    {
        name: "Transaction Type",
        selector: (row: any) => capitalizeFistLetterInSentence(row.type),
    },
    {
        name: "Amount",
        selector: (row: any) => `₦ ${numberFormat(row.amount)}`,
    },
    {
        name: "Customer",
        selector: (row: any) => row.customerFullName,
        grow: 1,
    },
    {
        name: "Customer Phone",
        selector: (row: any) => row.customerPhoneNumber,
    },
    {
        name: "Status",
        selector: (row: any) => capitalizeFistLetterInSentence(row.status),
        sortable: true,
    },
    {
        name: "Date Created",
        selector: (row: any) => formatDate(row.date_created),
    },
];

const Transactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [queryObject, setQueryObject] = useState({});
    const [userDetails, setUserDetails] = useState<any>({});

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [maxRow, setMaxRow] = useState(20);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pagination, setPagination] = useState({
        perPage: 5000,
        page: 1,
    });

    const [currentPage, setCurrentPage] = useState(1);

    const handleFilterChoice = () => {
        setShowFilter(true);
    };

    const getPageData = (current: number, paginationLength: number) => {
        const startIndex = (current - 1) * paginationLength;
        const endIndex = startIndex + paginationLength;

        return transactions.slice(startIndex, endIndex);
    };

    const handlePagination = (value: number) => {
        setCurrentPage(value);

        const pageData = getPageData(value, maxRow);
        setFilteredTransactions(pageData);
    };

    const showDetails = (row: any) => {
        setSelectedRow({
            reference: row.paymentReference,
            amount: `₦${row.amount}`,
            balance_before: `₦${row.balanceBefore}`,
            balance_after: `₦${row.balanceAfter}`,
            beneficiary_name: row.beneficiaryName,
            category: row.category,
            description: row.description,
            transaction_type: row.type,
            payment_method: row.paymentMethod,
            status: row.status,
            created_at: formatDate(row.date_created),
        });
        setShowModal(true);
    };

    const fetchTransactions = async (
        pageLimit?: number,
        // pageNumber?: number,
        query?: any
    ) => {
        setLoading(true);
        try {
            const res = await getTransactionsApi(
                pageLimit || pagination.perPage,
                pagination.page,
                query
            );
            const isEmpty = Object.keys(res.extras).length === 0;
            setTransactions(res.transactions);
            if (!isEmpty) setUserDetails(res.extras);

            return res;
        } catch (error) {
            console.log(error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = () => {
        fetchTransactions();
    };

    const filterTransactions = async (query: any) => {
        setShowFilter(false);

        const isPresent = Object.keys(query).includes("userPhoneNumber");
        if (Object.entries(query).length === 0) {
            await fetchTransactions(pagination.perPage, query);
            setQueryObject({});
            setUserDetails({});
        } else if (!isPresent) {
            await fetchTransactions(pagination.perPage, query);
            const filtered = Object.entries(query).filter(e => e[1] !== "");
            setQueryObject(Object.fromEntries(filtered));
            setUserDetails({});
        } else {
            await fetchTransactions(pagination.perPage, query);
            const filtered = Object.entries(query).filter(e => e[1] !== "");
            setQueryObject(Object.fromEntries(filtered));
        }
    };

    function filterDataByDateRange(data: any, startDate: any, endDate: any) {
        return data.filter(
            (item: any) =>
                new Date(
                    item.date_created.toString().split("T")[0]
                ).getTime() >= new Date(startDate).getTime() &&
                new Date(
                    item.date_created.toString().split("T")[0]
                ).getTime() <= new Date(endDate).getTime()
        );
    }

    const handleDateFilter = async (start: any, end: any) => {
        await fetchTransactions().then(res => {
            const data = filterDataByDateRange(res.transactions, start, end);
            setTransactions(data);
        });

        // Reset Pagination
        setCurrentPage(1);
        handlePagination(currentPage);
    };

    const onNext = async () => {
        const newPage = pagination.perPage + pagination.perPage;
        await fetchTransactions(newPage, queryObject);
        setPagination({ ...pagination, perPage: newPage });
    };

    useEffect(() => {
        fetchTransactions();
    }, []);

    useEffect(() => {
        handlePagination(currentPage);
    }, [transactions]);
    return (
        <section className="flex flex-col gap-4">
            <div className="w-full min-h-[50vh] overflow-y-auto">
                <DataTable
                    columns={columns}
                    data={filteredTransactions}
                    highlightOnHover
                    responsive
                    pointerOnHover
                    subHeader
                    subHeaderComponent={
                        <TableSubHeader
                            handleDateChange={handleDateFilter}
                            handleFilter={handleFilterChoice}
                            queryObject={queryObject}
                            filterTransaction={filterTransactions}
                            handleRefresh={handleRefresh}
                        />
                    }
                    onRowClicked={showDetails}
                    subHeaderWrap
                    progressPending={loading}
                    progressComponent={<Loading />}
                />
            </div>
            <div className="flex flex-col w-full">
                {!loading && (
                    <PaginationFilter
                        currentPage={currentPage}
                        totalPages={Math.ceil(transactions.length / maxRow)}
                        onPageChange={handlePagination}
                    />
                )}
            </div>
            <div className="flex flex-col items-center mt-2 text-xs text-white">
                <button
                    disabled={loading}
                    type="button"
                    className="px-6 py-4 bg-neutral-700 rounded-full w-fit flex gap-2 items-center"
                    onClick={onNext}
                >
                    {loading && <CustomLoader size="base" />}
                    Load More
                </button>
            </div>

            {showModal && (
                <TransactionDetail
                    open
                    toggleModal={() => setShowModal(false)}
                    details={selectedRow}
                    userRecord={userDetails}
                />
            )}
            {showFilter && (
                <FilterByDate
                    open
                    handleClose={() => setShowFilter(false)}
                    handleFilter={filterTransactions}
                />
            )}
        </section>
    );
};

export default Transactions;
