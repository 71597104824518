/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { walletInstance } from "../../../axios";

import { getCookie, setCookie } from "../../../utils/cookiesHandler";

// const communityId = getCookie('communityId')
const accessToken = getCookie("accessToken");

export const getCommunitiesApi = async (
    pageLimit?: any,
    pageNumber?: any,
    query?: any
) => {
    try {
        let url = `/communities?pageNumber=${pageNumber}&pageLimit=${pageLimit}`;
        if (query) {
            Object.keys(query).forEach(key => {
                url += `&${key}=${query[key]}`;
            });
        }
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getCommunityCategoriesApi = async () => {
    try {
        const url = "/communitycategories";
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getConversationNotesApi = async (
    pageLimit?: any,
    pageNumber?: any,
    query?: any
) => {
    try {
        let url = `/communitysuccess/notes?pageNumber=${pageNumber}&pageLimit=${pageLimit}`;
        if (query) {
            Object.keys(query).forEach(key => {
                url += `&${key}=${query[key]}`;
            });
        }
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getConversationFeedsApi = async (
    pageLimit?: any,
    pageNumber?: any,
    query?: any
) => {
    try {
        let url = `/communitysuccess/feed?pageNumber=${pageNumber}&pageLimit=${pageLimit}`;
        if (query) {
            Object.keys(query).forEach(key => {
                url += `&${key}=${query[key]}`;
            });
        }
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const createCommunityApi = async (payload: any) => {
    try {
        const url = "/communities";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const deleteCommunityApi = async (payload: any) => {
    try {
        const url = "/communities/delete";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const retrieveWalletStatementApi = async (
    payload: any
): Promise<Record<string, any>> => {
    const { from, to, commId } = payload;
    setCookie("communityId", commId);
    try {
        const url = `wallet/transactions/statement?from=${from}&to=${to}`;
        const res = await walletInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

// export const retrieveWalletStatementApi = async (payload: any) => {
//     const accToken = getCookie("accessToken");

//     const { from, to } = payload;
//     // setCookie('communityId', communityId as string)
//     setCookie("Access-Token", accToken as string);
//     const Cookies = `Access-Token=${accToken}`;
//     const url = `wallet/transactions/statement?from=${from}&to=${to}`;
//     const headers = {
//         // eslint-disable-next-line prettier/prettier
//         Cookie: Cookies,
//     };
//     try {
//         const res = await walletInstance.get(url, { headers });
//         return res.data;
//     } catch (err) {
//         throw err;
//     }

// const res = await fetch(url, {
//     method: "GET",
//     credentials: "include", // Include cookies in the request
//     headers: {
//         "Content-Type": "application/json",

//         // Add other headers as needed
//     },
// })
//     .then(response => response.json())
//     .then(data => data)
//     .catch(error => {
//         throw error;
//     });
// };
