/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Loader } from "rsuite";
import { dynamicUserFilterApi } from "../../../redux/slice/monitor/monitor.api";
import { CustomErrorToast } from "../../../components/blocks/Alert";
import { getAllTagsApi } from "../../../redux/slice/tags/tags.api";

interface DynamicFilterProps {
    items: string[];
    onFilterSubmit: (filteredData: any) => void; // Callback to handle filtered data
}

interface Tag {
    type: string;
    _id: string;
    label: string;
    color: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

const DynamicFilter: React.FC<DynamicFilterProps> = ({
    items,
    onFilterSubmit,
}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formValue, setFormValue] = useState<{
        from: string | null;
        to: string | null;
    }>({
        from: null,
        to: null,
    });

    const [tags, setTags] = useState<Tag[]>([]);
    const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
    const [fetchLoading, setFetchLoading] = useState<boolean>(true);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = async () => {
        if (!formValue.from || !formValue.to) {
            alert("Please select both 'From' and 'To' dates.");
            return;
        }
        if (!selectedTagId) {
            alert("Select a Tag");
            return;
        }

        setLoading(true);

        const payload = {
            tagId: selectedTagId,
            filterValues: {
                lastTransactionDate: {
                    from: formValue.from, // Already in YYYY-MM-DD format
                    to: formValue.to, // Already in YYYY-MM-DD format
                },
            },
            items,
        };

        try {
            const response = await dynamicUserFilterApi(payload); // Replace with your API endpoint
            onFilterSubmit(response.data); // Pass filtered data to parent component
            handleClose();
        } catch (error) {
            console.error("Error applying filter:", error);
            CustomErrorToast("Failed to apply filter. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleTagSelection = (tagId: string) => {
        setSelectedTagId((prevSelected: any) => {
            if (prevSelected === tagId) {
                return null;
            }
            return tagId;
        });
    };

    const fetchTags = async () => {
        try {
            setFetchLoading(true);
            const res = await getAllTagsApi();
            console.log("Tags: ", res.data.data);
            setTags(res.data.data);
            return res;
        } catch (error) {
            return null;
        } finally {
            setFetchLoading(false);
        }
    };

    useEffect(() => {
        fetchTags();
    }, []);

    return (
        <>
            <Button
                appearance="primary"
                className="bg-emerald-600 py-2 px-4 text-white w-full max-w-[180px] hover:bg-emerald-700 rounded"
                onClick={handleOpen}
            >
                Dynamic Filter
            </Button>

            <Modal open={open} onClose={handleClose} size="sm">
                <Modal.Header>
                    <Modal.Title>Dynamic Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex flex-row flex-wrap w-full gap-2 pb-6 border-b">
                        {fetchLoading ? (
                            [...Array(3)].map((_, index) => (
                                <div
                                    key={index}
                                    className="p-2 min-w-[160px] max-w-xs border rounded-lg bg-white border-gray-200 flex flex-row gap-2 animate-pulse"
                                >
                                    <div className="w-5 h-5 bg-gray-200 rounded-full" />
                                    <div className="flex flex-col flex-1">
                                        <div className="h-4 bg-gray-200 rounded w-3/4 mb-1" />
                                    </div>
                                </div>
                            ))
                        ) : tags.length === 0 ? (
                            <p className="text-center text-gray-500 bg-slate-50 rounded p-2 w-full">
                                No tags yet.
                            </p>
                        ) : (
                            tags
                                .filter(tag => tag.type === "dynamic")
                                .map(tag => (
                                    <div
                                        role="button"
                                        key={tag._id}
                                        className={`p-2 min-w-[160px] max-w-xs border rounded-lg bg-white border-gray-200 flex flex-row gap-2 cursor-pointer ${
                                            selectedTagId === tag._id
                                                ? "border-blue-500 bg-blue-50"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            handleTagSelection(tag._id)
                                        }
                                    >
                                        <div
                                            className="w-5 h-5 rounded-full"
                                            style={{
                                                backgroundColor: tag.color,
                                            }}
                                        />
                                        <div className="flex flex-col">
                                            <p className="text-sm font-medium">
                                                {tag.label}
                                            </p>
                                        </div>
                                    </div>
                                ))
                        )}
                    </div>
                    <Form fluid>
                        <Form.Group>
                            <Form.ControlLabel>From Date</Form.ControlLabel>
                            <input
                                type="date"
                                value={formValue.from || ""}
                                onChange={e =>
                                    setFormValue({
                                        ...formValue,
                                        from: e.target.value,
                                    })
                                }
                                className="rs-input"
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    borderRadius: "4px",
                                    border: "1px solid #ddd",
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>To Date</Form.ControlLabel>
                            <input
                                type="date"
                                value={formValue.to || ""}
                                onChange={e =>
                                    setFormValue({
                                        ...formValue,
                                        to: e.target.value,
                                    })
                                }
                                className="rs-input"
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    borderRadius: "4px",
                                    border: "1px solid #ddd",
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        appearance="primary"
                        className="bg-blue-600 py-2 px-4 text-white w-full max-w-[180px] hover:bg-blue-700 rounded"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? <Loader /> : "Apply Filter"}
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DynamicFilter;
