/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
// import DataTable, { TableColumn } from "react-data-table-component";
import { useNavigate, useParams } from "react-router";
import { Button } from "rsuite";
import PageHelmet from "../../../components/blocks/PageHelmet";
import numberFormat from "../../../utils/numberFormat";
// import formatDate from "../../../utils/formatDate";
// import SearchInput from "../../../components/elements/Inputs/SearchInput";
import { ErrorToast } from "../../../components/blocks/Alert";
import LBreadCrumb from "../../../components/elements/BreadCrumb/indext";
// import Loading from "../../../components/elements/Loading/Loading";
// import { MainButton } from "../../../components/elements/Button";
// import TerminalDetails from "./TerminalDetails";
import { GetDefaultingTerminalDetailsApi } from "../../../redux/slice/posterminals/posterminals.api";
import TerminalInfo from "./TerminalInfo";
import DailyTransactions from "./DailyTransaction";
// import DailyTransactions from "./DailyTransactions";
// import TerminalInfo from "./TerminalInfo";

interface iTerminalDetails {
    amountProcessed: number;
    basePayout: number;
    payoutLimit: number;
    transactions: any[];
    dailyTransactions: any[];
    terminalId: string;
    serialNumber: string;
    accountName: string;
    phoneNumber: string;
    initialAccountName: string;
    initialPhoneNumber: string;
    score: number;
    accountReference: string;
    lolaCustomerUserId: string;
    createdAt: string;
    updatedAt: string;
}

// const columns: TableColumn<any>[] = [
//     { name: "Description", selector: (row) => row.description },
//     { name: "Amount", selector: (row) => `₦ ${numberFormat(row.amount)}`, sortable: true },
//     { name: "Transaction Type", selector: (row) => row.transactionType },
//     { name: "Transaction Date", selector: (row) => formatDate(row.dateOfTransaction) },
//     {
//         name: "Status", cell: (row) => (
//             <span className={`font-semibold ${row.status === "successful" ? "text-green-500" : "text-red-500"}`}>
//                 {row.status}
//             </span>
//         )
//     }
// ];

const DefaultingTerminalTransactions = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [terminalDetails, setTerminalDetails] =
        useState<iTerminalDetails | null>(null);

    const navigate = useNavigate();

    const BreadCrumbData = [
        { id: 0, link: "/pos-terminals", title: "Terminals" },
        { id: 1, link: "#", title: "Defaulting Terminals" },
        { id: 2, isActive: true, title: id ?? "N/A" },
    ];

    const fetchTerminalTransaction = async () => {
        try {
            if (!id) return;
            setLoading(true);
            const res = await GetDefaultingTerminalDetailsApi(id);
            setTerminalDetails(res.data[0]);
            console.log("Term: ", res.data[0]);
        } catch (err) {
            ErrorToast(err);
        } finally {
            setLoading(false);
        }
    };

    const handleReach = () => {
        navigate("/workspaces", {
            state: { specificCustomerPhone: terminalDetails?.phoneNumber },
        });
    };

    useEffect(() => {
        if (id) fetchTerminalTransaction();
    }, [id]);

    // const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    //     setFormData({ ...formData, [e.target.name]: e.target.value });
    // };

    return (
        <div className="container mx-auto p-6">
            <PageHelmet title="Terminal Transactions" />
            <LBreadCrumb data={BreadCrumbData} />

            {terminalDetails && (
                <>
                    <div className="bg-white border shadow-lg rounded-lg p-6 my-6 flex justify-between gap-6 flex-wrap">
                        <div className="">
                            <h3 className="text-2xl font-bold">
                                {id} POS Terminal
                            </h3>
                            <p className="text-gray-600">
                                Manage terminal transactions and financial
                                details.
                            </p>
                        </div>
                        <Button
                            appearance="primary"
                            className="bg-[#006399] py-1.5 px-4 text-white w-full max-w-[180px] hover:bg-[#0073b3] rounded"
                            onClick={handleReach}
                        >
                            <b>Reach Out to</b>
                            <br />
                            <small>{terminalDetails.accountName}</small>
                        </Button>
                    </div>

                    <TerminalInfo
                        terminalId={terminalDetails.terminalId}
                        serialNumber={terminalDetails.serialNumber}
                        accountName={terminalDetails.accountName}
                        phoneNumber={terminalDetails.phoneNumber}
                        initialAccountName={terminalDetails.initialAccountName}
                        initialPhoneNumber={terminalDetails.initialPhoneNumber}
                        score={terminalDetails.score}
                        accountReference={terminalDetails.accountReference}
                        lolaCustomerUserId={terminalDetails.lolaCustomerUserId}
                        createdAt={terminalDetails.createdAt}
                        updatedAt={terminalDetails.updatedAt}
                    />

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-6">
                        {[
                            {
                                label: "Amount Processed",
                                value: numberFormat(
                                    terminalDetails.amountProcessed
                                ),
                            },
                            {
                                label: "Base Payout",
                                value: numberFormat(terminalDetails.basePayout),
                            },
                            {
                                label: "Payout Limit",
                                value: numberFormat(
                                    terminalDetails.payoutLimit
                                ),
                            },
                        ].map((item, idx) => (
                            <div
                                key={idx}
                                className="bg-gray-100 p-6 rounded-lg text-center shadow"
                            >
                                <p className="text-lg font-medium">
                                    {item.label}
                                </p>
                                <h4 className="text-2xl font-bold">
                                    ₦ {item.value}
                                </h4>
                            </div>
                        ))}
                    </div>

                    <DailyTransactions
                        dailyTransactions={terminalDetails.dailyTransactions}
                    />
                </>
            )}

            {/* <div className="flex justify-between items-center mb-6">
                <h4 className="text-xl font-semibold">Transaction History</h4>
                <SearchInput value={formData.search} onChange={handleInputChange} name="search" placeholder="Search" />
                <MainButton onClick={() => setShowTerminalDetails(true)}>View Terminal Details</MainButton>
            </div>

            <DataTable
                columns={columns}
                data={terminalTransactions}
                highlightOnHover
                responsive
                pagination
                progressPending={loading}
                progressComponent={<Loading />}
            />

            {showTerminalDetails && (
                <TerminalDetails open toggleModal={() => setShowTerminalDetails(false)} terminalID={id || "N/A"} />
            )} */}
        </div>
    );
};

export default DefaultingTerminalTransactions;
