import React, { useState } from "react";
import { Button, Modal } from "rsuite";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import { roleOptions } from "../../../lib/data/roles";

type Props = {
    open: boolean;
    handleClose: () => void;
    handleFilter: (query: any) => void;
};

// const options = [
//     { name: "Guest Support", value: "GUEST_SUPPORT" },
//     { name: "Customer Support", value: "CS" },
//     { name: "Frontline Sales Officer", value: "FSO" },
//     { name: "State Manager", value: "STATE_MANAGER" },
//     { name: "HQ Employee", value: "HQ_EMPLOYEE" },
//     { name: "HQ Manager", value: "HQ_MANAGER" },
// ];

const FilterStaff = ({ open, handleClose, handleFilter }: Props) => {
    const [formData, setFormData] = useState({
        operationsStaffLevel: "",
        lineManagerUserId: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Filter Staff
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FloatingLabelSelectInput
                    options={roleOptions}
                    label="Operation Staff Level"
                    onChange={handleInputChange}
                    name="operationsStaffLevel"
                    value={formData.operationsStaffLevel}
                />
            </Modal.Body>
            <div className="flex flex-col justify-center">
                <DrawerButton onClick={() => handleFilter(formData)}>
                    Filter
                </DrawerButton>
                <Button
                    onClick={handleClose}
                    className="text-red-600 bg-slate-100"
                >
                    Cancel
                </Button>
            </div>
        </Modal>
    );
};

export default FilterStaff;
