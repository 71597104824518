/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { Plus } from "lucide-react";
import { Button, Loader, Modal } from "rsuite";
import { z } from "zod";
import { SuccessToast, ErrorToast } from "../../../components/blocks/Alert";
import { bulkUploadUsersToBucketApi } from "../../../redux/slice/monitor/monitor.api";

// Define the schema for the CSV file
const CsvFileSchema = z.object({
    file: z.instanceof(File).refine(file => file.type === "text/csv", {
        message: "Only CSV files are allowed.",
    }),
});

type Props = {
    bucketId: string | undefined;
};

const BulkAddUser = ({ bucketId }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
            setError(null); // Clear any previous errors
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!bucketId) return;

        if (!file) {
            setError("Please select a CSV file.");
            return;
        }

        try {
            setLoading(true);
            // Validate the file against the schema
            CsvFileSchema.parse({ file });

            // Call the bulk upload API
            await bulkUploadUsersToBucketApi(bucketId, file);

            // Show success message
            SuccessToast("CSV file uploaded successfully!");

            // Close the modal
            setOpen(false);
        } catch (err) {
            if (err instanceof z.ZodError) {
                setError(err.errors[0].message);
            } else {
                ErrorToast("An error occurred while uploading the file.");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                appearance="primary"
                className="flex items-center bg-indigo-400 hover:bg-indigo-500"
            >
                <Plus className="mr-2 h-4 w-4" /> Bulk Add Users
            </Button>
            <Modal open={open} onClose={() => setOpen(false)} size="xs">
                <Modal.Header>
                    <Modal.Title>Bulk Add Users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit}
                        className="space-y-4 flex flex-col"
                    >
                        <div>
                            <label
                                htmlFor="csvFile"
                                className="block font-medium mb-1"
                            >
                                Upload CSV File
                            </label>
                            <input
                                id="csvFile"
                                name="csvFile"
                                type="file"
                                accept=".csv"
                                onChange={handleFileChange}
                                className="w-full border border-gray-300 rounded p-2"
                            />
                            {error && (
                                <p className="text-red-500 text-sm mt-1">
                                    {error}
                                </p>
                            )}
                        </div>

                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded ml-auto"
                            disabled={loading}
                        >
                            {loading ? <Loader /> : "Upload CSV"}
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BulkAddUser;
