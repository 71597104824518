/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import { validateAll } from "indicative/validator";
// import { createReminderApi } from "../../../../redux/slice/reminder/reminder.api";
import {
    CustomErrorToast,
    InfoToast,
    SuccessToast,
} from "../../../../components/blocks/Alert";
import FloatingLabelSelectInput from "../../../../components/elements/Inputs/FloatingLabelSelectInput";
import FloatingLabelInput from "../../../../components/elements/Inputs/FloatingLabelInput";
import { DrawerButton } from "../../../../components/elements/Button";
import Loading from "../../../../components/elements/Loading/Loading";
import { createStaffReminderApi } from "../../../../redux/slice/reminder/reminder.api";

// Reminder data type
type Reminder = {
    type: "follow_up" | "task";
    description: string;
    assignedTo: string;
    dueDate: string;
    priority: "high" | "medium" | "low";
};

const TypeOptions = [
    { name: "Follow Up", value: "follow_up" },
    { name: "Task", value: "task" },
];

const PriorityOptions = [
    { name: "High", value: "high" },
    { name: "Medium", value: "medium" },
    { name: "Low", value: "low" },
];

type Props = {
    open: boolean;
    handleClose: () => void;
    staff: any;
    // reminders: Reminder;
};

const AddTaskModal = ({ open, handleClose, staff }: Props) => {
    const [formData, setFormData] = useState<Reminder>({
        type: "task",
        description: "",
        assignedTo: "",
        dueDate: "",
        priority: "medium",
    });

    const logData = staff.map((user: any) => ({
        name: `${user.first_name} ${user.last_name}`,
        value: user.userId,
    }));

    const [error, setError] = useState<any>("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processCreate = async (data: any) => {
        setLoading(true);
        try {
            const priorityMap: Record<string, number> = {
                high: 3,
                medium: 2,
                low: 1,
            };
            // const { assignedTo, ...restData } = data;
            const formattedData = {
                ...data,
                dueDate: new Date(data.dueDate).toISOString(),
                priority: priorityMap[data.priority] || 2,
                // metadata: {
                //     userPhoneNumber: assignedTo
                // }
            };
            console.log("Task Data: ", formattedData);
            const res = await createStaffReminderApi(formattedData);
            if (res.successful) SuccessToast("Task assigned successfully");
            if (!res.successful) InfoToast(res.message);
            setLoading(false);
            handleClose();
        } catch (err: any) {
            setLoading(false);
            CustomErrorToast(err);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            type: "required|in:follow_up,task",
            description: "required|string",
            assignedTo: "required|string",
            dueDate: "required|string",
            priority: "required|in:high,medium,low",
        };

        const messages = {
            "type.required": "Reminder type is required",
            "type.in": "Invalid reminder type",
            "description.required": "Reminder description is required",
            "description.string": "Description must be a string",
            "assignedTo.required": "Assigned person is required",
            "assignedTo.string": "Assigned person must be a valid string",
            "dueDate.required": "Due date is required",
            "dueDate.date": "Please provide a valid date",
            "priority.required": "Priority is required",
            "priority.in": "Priority must be High, Medium, or Low",
        };

        validateAll(formData, rules, messages)
            .then(() => processCreate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    useEffect(() => {
        if (staff.length < 1) return;
        console.log("Staff: ", staff);
    }, [staff]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Create & Assign Tas to a Staff
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={TypeOptions}
                        label="Type"
                        onChange={handleInputChange}
                        name="type"
                        value={formData.type}
                        error={error.type}
                    />
                </div> */}
                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="Reminder Description"
                        onChange={handleInputChange}
                        name="description"
                        value={formData.description}
                        error={error.description}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="date"
                        label="Due Date"
                        onChange={handleInputChange}
                        name="dueDate"
                        value={formData.dueDate}
                        error={error.dueDate}
                    />
                </div>
                {/* <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="Assigned To"
                        onChange={handleInputChange}
                        name="assignedTo"
                        value={formData.assignedTo}
                        error={error.assignedTo}

                    />
                </div> */}
                <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={logData}
                        label="Assigned To"
                        onChange={handleInputChange}
                        name="assignedTo"
                        value={formData.assignedTo}
                        error={error.assignedTo}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={PriorityOptions}
                        label="Priority"
                        onChange={handleInputChange}
                        name="priority"
                        value={formData.priority}
                        error={error.priority}
                    />
                </div>
                <div className="flex flex-col justify-center">
                    <DrawerButton onClick={handleSubmit}>
                        {loading ? <Loading size="sm" /> : "Create Reminder"}
                    </DrawerButton>
                    <Button
                        onClick={handleClose}
                        className="text-red-600 bg-slate-100 hover:text-red-600"
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddTaskModal;
