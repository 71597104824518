import React, { useState } from "react";
import { Button, Modal } from "rsuite";
import { DrawerButton } from "../../../../components/elements/Button";
import FloatingLabelInput from "../../../../components/elements/Inputs/FloatingLabelInput";
import FloatingLabelSelectInput from "../../../../components/elements/Inputs/FloatingLabelSelectInput";

type Props = {
    open: boolean;
    handleClose: () => void;
    handleFilter: (query: any) => void;
};

const FilterByDate = ({ open, handleClose, handleFilter }: Props) => {
    const [formData, setFormData] = useState({
        fromDate: "",
        toDate: "",
        status: "",
        userId: "",
        userPhoneNumber: "",
        accountNumber: "",
    });

    const options = [
        { name: "Successful", value: "successful" },
        { name: "Failed", value: "failed" },
    ];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Filter Transactions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="date"
                        label="Start date"
                        onChange={e => handleInputChange(e)}
                        name="fromDate"
                        value={formData.fromDate}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="date"
                        label="End date"
                        onChange={e => handleInputChange(e)}
                        name="toDate"
                        value={formData.toDate}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={options}
                        label="Status"
                        onChange={handleInputChange}
                        name="status"
                        value={formData.status}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="User ID"
                        onChange={e => handleInputChange(e)}
                        name="userId"
                        value={formData.userId}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="User Phone Number"
                        onChange={e => handleInputChange(e)}
                        name="userPhoneNumber"
                        value={formData.userPhoneNumber}
                    />
                </div>
                <FloatingLabelInput
                    type="text"
                    label="Account Number"
                    onChange={e => handleInputChange(e)}
                    name="accountNumber"
                    value={formData.accountNumber}
                />
            </Modal.Body>
            <div className="flex flex-col justify-center">
                <DrawerButton onClick={() => handleFilter(formData)}>
                    Filter
                </DrawerButton>
                <Button
                    onClick={handleClose}
                    className="text-red-600 bg-slate-100"
                >
                    Cancel
                </Button>
            </div>
        </Modal>
    );
};

export default FilterByDate;
