/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import AddTaskModal from "./TaskFormModal";

interface Props {
    staff: any;
}

const AddStaffTask = ({ staff }: Props) => {
    const [addReminder, setAddReminder] = useState(false);
    const [logData, setLogData] = useState<any[]>([]);

    useEffect(() => {
        if (staff.length < 1) return;
        const logs = staff.map((user: any) => ({
            first_name: user.userRecord.first_name,
            last_name: user.userRecord.last_name,
            userId: user.userId,
        }));
        setLogData(logs);
        console.log(logs);
    }, [staff]);

    return (
        <>
            <Button
                appearance="primary"
                className="bg-[#006399] py-1.5 px-4 text-white w-full max-w-[180px] hover:bg-[#0073b3] rounded"
                onClick={() => setAddReminder(true)}
            >
                Create Task
            </Button>

            {addReminder && (
                <AddTaskModal
                    staff={logData}
                    open
                    handleClose={() => setAddReminder(false)}
                />
            )}
        </>
    );
};

export default AddStaffTask;
