import React, { useEffect, useState } from "react";
import store from "store";
import { useLocation } from "react-router";
import DataTable from "react-data-table-component";
import {
    ErrorToastWString,
    SuccessToast,
} from "../../../components/elements/Alerts";
import { CustomButton } from "../../../components/elements/Button";
import FloatingInputAddon from "../../../components/elements/Inputs/FloatingInputAddon";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import SearchInput from "../../../components/elements/Inputs/SearchInput";
import Loading from "../../../components/elements/Loading/Loading";
import { communitySuccessApi } from "../../../redux/slice/workspaces/workspaces.api";
import RecordFeedback from "./RecordFeedback";
import formatDate from "../../../utils/formatDate";
import {
    generateCredentialsApi,
    resendAccountDetailsApi,
} from "../../../redux/slice/customers/customers.api";
import TextAreaGroup from "../../../components/elements/Inputs/TextAreaGroup/TextAreaGroup";
import ConfirmationModal from "../../../components/blocks/Modal/ConfirmationModal";
import PaginationFilter from "../../../components/elements/paginationFilter";

const callColumns = [
    {
        name: "Call Started At",
        selector: (row: any) => formatDate(row.callStartedAt),
    },
    {
        name: "Call Ended At",
        selector: (row: any) => formatDate(row.callEndedAt),
    },
];

const followUpColumns = [
    {
        name: "Follow Up Status",
        selector: (row: any) => row.followUpHasBeenDone,
        sortable: true,
        cell: (row: any) => (
            <div>
                {row.followUpHasBeenDone ? (
                    <div className="font-semibold text-success-600">
                        Follow Successful
                    </div>
                ) : (
                    <div className="font-semibold text-red-600">
                        Not followed up yet
                    </div>
                )}
            </div>
        ),
    },
    {
        name: "Date To Be Followed Up",
        selector: (row: any) => formatDate(row.dateTimeToFollowUp),
    },
    {
        name: "Date Created",
        selector: (row: any) => formatDate(row.createdAt),
    },
];

const notesColumns = [
    {
        name: "Feedback",
        selector: (row: any) => row.noteContent,
        grow: 2,
    },
    {
        name: "Contacted Via",
        selector: (row: any) => row.correlatingConversation.type,
    },
    {
        name: "Contacted Via",
        selector: (row: any) => formatDate(row.createdAt),
    },
];

const transactionColumns = [
    { name: "Transaction ID", selector: (row: any) => row.transactionId },
    { name: "Reference", selector: (row: any) => row.transactionReference },
    {
        name: "Amount (NGN)",
        selector: (row: any) => row.amount,
        sortable: true,
    },
    {
        name: "Description",
        selector: (row: any) => row.description,
    },
    { name: "Category", selector: (row: any) => row.category, sortable: true },
    { name: "Status", selector: (row: any) => row.status, sortable: true },
    {
        name: "Created At",
        selector: (row: any) => formatDate(row.date_created),
    },
];

const Workspaces = () => {
    const userId = store.get("userId");
    const location = useLocation() as { state: any | null };
    const [customer, setCustomer] = useState<any>({});
    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [resending, setResending] = useState(false);
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [recordFeedback, setRecordFeedback] = useState<boolean>(false);
    const [callStartedAt, setTimeStamps] = useState<Date>(new Date());
    const [specificCustomerPhone, setSpecificCustomerPhone] = useState<any>("");
    const [shouldReset, setShouldReset] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [maxRow, setMaxRow] = useState(16);

    const handleCall = () => {
        const timeCalled = new Date();
        setTimeStamps(timeCalled);
        setDisableButton(true);
    };

    const handleRecord = () => {
        setDisableButton(true);
        setRecordFeedback(true);
    };

    const fetchCommunitySuccesses = async (query?: any) => {
        setLoading(true);
        try {
            const res = await communitySuccessApi(userId, query);
            if (!res.successful) {
                ErrorToastWString(res.message);
            }
            if (res.successful) {
                setTransactions(res.transactionHistory);
                setCustomer({
                    callHistory: res.callHistory,
                    customerToCall: res.customerToCall,
                    notesHistory: res.notesHistory,
                    transactionHistory: res.transactionHistory,
                    walletInformation: res.walletInformation,
                    customerPennedFollowUpsHistory:
                        res.customerPennedFollowUpsHistory,
                });
            }
            setLoading(false);
            setSpecificCustomerPhone("");
        } catch (error) {
            throw error;
        }
    };

    const getPageData = (current: number, paginationLength: number) => {
        const startIndex = (current - 1) * paginationLength;
        const endIndex = startIndex + paginationLength;

        return transactions.slice(startIndex, endIndex);
    };

    const handlePagination = (value: number) => {
        setCurrentPage(value);

        const pageData = getPageData(value, maxRow);
        setFilteredTransactions(pageData);
    };

    const handleCloseRecord = (status: any) => {
        setRecordFeedback(false);
        if (status) {
            setDisableButton(false);
            fetchCommunitySuccesses(specificCustomerPhone);
        }
    };

    const handleReset = async () => {
        setResending(true);
        const payload = {
            userId: customer.customerToCall.userId,
        };
        try {
            const res = await generateCredentialsApi(payload);

            if (res.successful) {
                SuccessToast(
                    "Password and Pin created successully and sent to customer's phone"
                );
            }
            if (!res.successful) {
                ErrorToastWString("Something went error. Please try again");
            }
            setResending(false);
            setShouldReset(false);
        } catch (err) {
            setResending(false);
        }
    };

    const handleResend = async (choice: string) => {
        if (choice === "isAccount") {
            const payload = {
                lolaCustomerUserId: customer.customerToCall.userId,
            };
            try {
                setResending(true);
                const res = await resendAccountDetailsApi(payload);
                if (res.successful) {
                    SuccessToast(res.message);
                }
                if (!res.successful) {
                    ErrorToastWString(res.message);
                }
                setResending(false);
            } catch (err) {
                setResending(false);
            }
        }

        if (choice === "reset") {
            setShouldReset(true);
        }
    };

    const handleSearch = () => {
        fetchCommunitySuccesses(specificCustomerPhone);
    };

    useEffect(() => {
        handlePagination(currentPage);
    }, [transactions]);

    useEffect(() => {
        if (location?.state !== null) {
            console.log("State: ", location.state?.specificCustomerPhone);
            fetchCommunitySuccesses(location.state?.specificCustomerPhone);
        } else {
            fetchCommunitySuccesses();
        }
    }, []);

    return (
        <>
            <div className="px-2 sm:px-0">
                {loading ? (
                    <div className="flex items-center justify-center">
                        <Loading size="lg" />
                    </div>
                ) : (
                    <div>
                        <div className="flex flex-col justify-between mb-10 space-y-4 sm:flex-row sm:space-y-0">
                            <div className="flex md:w-[50%]">
                                <SearchInput
                                    name="specificCustomerPhone"
                                    placeholder="Customer Phone Number"
                                    value={specificCustomerPhone}
                                    onChange={e =>
                                        setSpecificCustomerPhone(e.target.value)
                                    }
                                />
                                <CustomButton
                                    buttonStyle="px-4 ml-4 py-0 bg-zinc-400 hover:bg-zinc-500 text-white text-sm rounded"
                                    onClick={handleSearch}
                                >
                                    Search
                                </CustomButton>
                            </div>
                            <div className="flex justify-between space-x-4">
                                <CustomButton
                                    buttonStyle="bg-[#009FF5] hover:bg-[#0074b3] text-white px-4 py-2 text-xs rounded"
                                    onClick={handleRecord}
                                    disabled={!disableButton}
                                >
                                    Record Call Feedback
                                </CustomButton>
                                <CustomButton
                                    buttonStyle="bg-[#009FF5] hover:bg-[#0074b3] text-white px-4 py-2 text-xs rounded"
                                    onClick={() => fetchCommunitySuccesses()}
                                    disabled={disableButton}
                                >
                                    Next Customer
                                </CustomButton>
                            </div>
                        </div>

                        {Object.keys(customer).length > 0 ? (
                            <>
                                <div className="flex flex-col space-y-14 sm:space-x-8 sm:space-y-0 sm:flex-row">
                                    <div className="w-full sm:w-[50%]">
                                        <div className="flex items-center justify-between mb-2">
                                            <h3 className="mb-2 text-base font-semibold text-[#F75500]">
                                                Customer To Call
                                            </h3>
                                            <div
                                                className="cursor-pointer hover:underline text-[#0074b3] hover:text-[#009ff5] font-medium"
                                                onClick={() =>
                                                    handleResend("reset")
                                                }
                                                role="button"
                                            >
                                                {resending ? (
                                                    <p className="animate-bounce">
                                                        Resetting pin ...
                                                    </p>
                                                ) : (
                                                    <p className="text-xs">
                                                        Reset Pin and Password
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <form className="w-full overflow-y-auto h-96">
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingLabelInput
                                                    disabled
                                                    label="Full Name"
                                                    value={`${customer?.customerToCall?.first_name} ${customer.customerToCall?.last_name}`}
                                                />
                                            </div>
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingInputAddon
                                                    label="Phone Number"
                                                    placeholder="Phone Number"
                                                    value={
                                                        customer?.customerToCall
                                                            ?.phone_number
                                                    }
                                                    disabled
                                                    onIconClick={handleCall}
                                                />
                                            </div>
                                            <div className="w-[90%] sm:w-[70%]">
                                                <TextAreaGroup
                                                    label="Goal Summary"
                                                    value={
                                                        customer?.customerToCall
                                                            ?.goal
                                                    }
                                                    rows={5}
                                                    disabled
                                                />
                                            </div>
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingLabelInput
                                                    disabled
                                                    label="BVN"
                                                    value={
                                                        customer?.customerToCall
                                                            ?.bvn
                                                    }
                                                />
                                            </div>
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingLabelInput
                                                    disabled
                                                    label="NIN"
                                                    value={
                                                        customer?.customerToCall
                                                            ?.nin
                                                    }
                                                />
                                            </div>
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingLabelInput
                                                    disabled
                                                    label="Daily Savings Amount"
                                                    value={
                                                        customer?.customerToCall
                                                            ?.loa_dailySavingsAmount
                                                    }
                                                />
                                            </div>
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingLabelInput
                                                    disabled
                                                    label="Easy Savings Account Number"
                                                    value={
                                                        customer?.customerToCall
                                                            ?.easy_savings_account_number ||
                                                        "Nil"
                                                    }
                                                />
                                            </div>
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingLabelInput
                                                    disabled
                                                    label="Registered On"
                                                    value={formatDate(
                                                        customer?.customerToCall
                                                            ?.date_created
                                                    )}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="w-full sm:w-[50%]">
                                        <div className="flex items-center justify-between mb-2">
                                            <h3 className="text-base font-semibold text-[#F75500]">
                                                Wallet Information
                                            </h3>
                                            <div
                                                className="cursor-pointer hover:underline text-[#0074b3] hover:text-[#009ff5] font-medium"
                                                onClick={() =>
                                                    handleResend("isAccount")
                                                }
                                                role="button"
                                            >
                                                {resending ? (
                                                    <p className="animate-bounce">
                                                        Sending details ...
                                                    </p>
                                                ) : (
                                                    <p className="text-xs">
                                                        Resend Customer Details
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <form className="w-full">
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingLabelInput
                                                    disabled
                                                    label="Account Number"
                                                    value={
                                                        customer
                                                            ?.walletInformation
                                                            ?.virtualAccountNumber
                                                    }
                                                />
                                            </div>
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingLabelInput
                                                    disabled
                                                    label="Bank"
                                                    value={
                                                        customer
                                                            ?.walletInformation
                                                            ?.virtualAccountBankName
                                                    }
                                                />
                                            </div>
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingLabelInput
                                                    disabled
                                                    label="Available Balance"
                                                    value={
                                                        customer
                                                            ?.walletInformation
                                                            ?.avaialbleBalance
                                                    }
                                                />
                                            </div>
                                            <div className="w-[90%] sm:w-[70%] py-1">
                                                <FloatingLabelInput
                                                    disabled
                                                    label="Bonus Balance"
                                                    value={
                                                        customer
                                                            ?.walletInformation
                                                            ?.bonusBalance
                                                    }
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="pt-10">
                                    <h3 className="mb-2 text-lg font-semibold text-[#F75500]">
                                        Notes History
                                    </h3>
                                    <div>
                                        {customer?.notesHistory?.length > 0 ? (
                                            <div>
                                                <DataTable
                                                    columns={notesColumns}
                                                    data={
                                                        customer?.notesHistory
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <p className="">
                                                No Records Founds
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="pt-10">
                                    <h3 className="mb-2 text-lg font-semibold text-[#F75500]">
                                        Call History
                                    </h3>
                                    <div>
                                        {customer?.callHistory?.length > 0 ? (
                                            <div>
                                                <DataTable
                                                    columns={callColumns}
                                                    data={customer?.callHistory}
                                                />
                                            </div>
                                        ) : (
                                            <p className="">
                                                No Records Founds
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="pt-10">
                                    <h3 className="mb-2 text-lg font-semibold text-[#F75500]">
                                        Pending Follow Ups
                                    </h3>
                                    <div>
                                        {customer
                                            ?.customerPennedFollowUpsHistory
                                            ?.length > 0 ? (
                                            <div>
                                                <DataTable
                                                    columns={followUpColumns}
                                                    data={
                                                        customer?.customerPennedFollowUpsHistory
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <p className="">
                                                No Records Founds
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="pt-10">
                                    <h3 className="mb-2 text-lg font-semibold text-[#F75500]">
                                        Transactions History
                                    </h3>
                                    <div>
                                        {customer?.transactionHistory?.length >
                                        0 ? (
                                            <div>
                                                <DataTable
                                                    columns={transactionColumns}
                                                    data={filteredTransactions}
                                                />
                                            </div>
                                        ) : (
                                            <p className="">
                                                No Records Founds
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <PaginationFilter
                                            currentPage={currentPage}
                                            totalPages={Math.ceil(
                                                transactions.length / maxRow
                                            )}
                                            onPageChange={handlePagination}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            "No Records Found"
                        )}
                    </div>
                )}
            </div>
            {recordFeedback && (
                <RecordFeedback
                    open
                    handleClose={handleCloseRecord}
                    callStartedAt={callStartedAt}
                    userData={{
                        lolaCustomerUserId: customer.customerToCall.userId,
                        lolaStaffUserId: userId,
                    }}
                />
            )}
            {shouldReset && (
                <ConfirmationModal
                    open
                    action={handleReset}
                    handleClose={() => setShouldReset(false)}
                    loading={resending}
                />
            )}
        </>
    );
};

export default Workspaces;
