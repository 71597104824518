import React from "react";

interface TerminalInfoProps {
    terminalId: string;
    serialNumber: string;
    accountName: string;
    phoneNumber: string;
    initialAccountName: string;
    initialPhoneNumber: string;
    score: number;
    accountReference: string;
    lolaCustomerUserId: string;
    createdAt: string;
    updatedAt: string;
}

const TerminalInfo: React.FC<TerminalInfoProps> = ({
    terminalId,
    serialNumber,
    accountName,
    phoneNumber,
    initialAccountName,
    initialPhoneNumber,
    score,
    accountReference,
    lolaCustomerUserId,
    createdAt,
    updatedAt,
}) => (
    <div className="bg-white shadow-lg rounded-lg p-6 my-6">
        <h3 className="text-2xl font-bold mb-4">Terminal Information</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
                <p className="text-gray-600">Terminal ID</p>
                <p className="text-lg font-semibold">{terminalId}</p>
            </div>
            <div>
                <p className="text-gray-600">Serial Number</p>
                <p className="text-lg font-semibold">{serialNumber}</p>
            </div>
            <div>
                <p className="text-gray-600">Account Name</p>
                <p className="text-lg font-semibold">{accountName}</p>
            </div>
            <div>
                <p className="text-gray-600">Phone Number</p>
                <p className="text-lg font-semibold">{phoneNumber}</p>
            </div>
            <div>
                <p className="text-gray-600">Initial Account Name</p>
                <p className="text-lg font-semibold">{initialAccountName}</p>
            </div>
            <div>
                <p className="text-gray-600">Initial Phone Number</p>
                <p className="text-lg font-semibold">{initialPhoneNumber}</p>
            </div>
            <div>
                <p className="text-gray-600">Score</p>
                <p className="text-lg font-semibold">{score}</p>
            </div>
            <div>
                <p className="text-gray-600">Account Reference</p>
                <p className="text-lg font-semibold">{accountReference}</p>
            </div>
            <div>
                <p className="text-gray-600">Lola Customer User ID</p>
                <p className="text-lg font-semibold">{lolaCustomerUserId}</p>
            </div>
            <div>
                <p className="text-gray-600">Created At</p>
                <p className="text-lg font-semibold">
                    {new Date(createdAt).toLocaleString()}
                </p>
            </div>
            <div>
                <p className="text-gray-600">Updated At</p>
                <p className="text-lg font-semibold">
                    {new Date(updatedAt).toLocaleString()}
                </p>
            </div>
        </div>
    </div>
);

export default TerminalInfo;
