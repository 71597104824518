/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/react-in-jsx-scope */
import { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router";
import PosIcon from "../../../assets/images/logo/pos-terminal 1.png";
import Loading from "../../../components/elements/Loading/Loading";
import { DbAllTerminals } from "../../../types/api.types";
import formatDate from "../../../utils/formatDate";
import ReAssignTerminal from "./ReassignTerminal";
import TerminalDetails from "./TerminalDetails";
import AddStaffTerminal from "./AssignStaffTTerminal";
import CustomLoader from "../../../components/elements/Loading/CustomLoader";

const SelectRowCheckbox = ({
    row,
    selectedTerminalIds,
    handleRowSelect,
}: any) => (
    <input
        type="checkbox"
        checked={selectedTerminalIds.includes(row.terminalId)}
        onChange={() => handleRowSelect(row)}
    />
);

export type SelectionType = {
    action: "details" | "re-assign";
    data: DbAllTerminals;
};

type Props = {
    allTerminals: DbAllTerminals[];
    loading: boolean;
    onTerminalDetailsClick(row: DbAllTerminals): void;
    onLoadMore: () => void;
};

const DefaultingTerminals = (props: Props) => {
    const navigate = useNavigate();
    const {
        allTerminals,
        loading,
        onTerminalDetailsClick: _actionForTerminalHistory,
        onLoadMore,
    } = props;

    const [selection, setSelection] = useState<SelectionType | undefined>(
        undefined
    );
    const [selectedTerminalIds, setSelectedTerminalIds] = useState<string[]>(
        []
    );

    const handleRowSelected = (rowData: DbAllTerminals) => {
        navigate(`reach-out/${rowData.terminalId}`, { state: rowData });
    };

    const handleSelectionClose = () => setSelection(undefined);

    const handleRowSelect = (rowData: DbAllTerminals) => {
        const { terminalId } = rowData;
        setSelectedTerminalIds(
            prevSelectedIds =>
                prevSelectedIds.includes(terminalId)
                    ? prevSelectedIds.filter(id => id !== terminalId) // Deselect if already selected
                    : [...prevSelectedIds, terminalId] // Select if not already selected
        );
    };

    const columns = [
        {
            name: "Select",
            cell: (row: DbAllTerminals) => (
                <SelectRowCheckbox
                    row={row}
                    selectedTerminalIds={selectedTerminalIds}
                    handleRowSelect={handleRowSelect}
                />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "S/N",
            selector: (row: DbAllTerminals) => row?.serialNumber || "N/A",
        },
        {
            name: "Terminal Id",
            selector: (row: DbAllTerminals) => row?.terminalId ?? "N/A",
        },
        {
            name: "Account Name",
            selector: (row: any) => row?.accountName ?? "N/A",
        },
        {
            name: "Phone Number",
            selector: (row: any) => row?.phoneNumber ?? "N/A",
        },
        {
            name: "Updated At",
            selector: (row: DbAllTerminals) => formatDate(row.createdAt as any),
        },
    ];

    return (
        <div>
            <div className="w-full">
                <div className="flex flex-row gap-2 overflow-x-auto">
                    {selectedTerminalIds.length > 0 &&
                        selectedTerminalIds.map((term: any) => (
                            <strong className="rounded border-2 my-2 p-2 w-fit">
                                {term}
                            </strong>
                        ))}
                </div>
                {selectedTerminalIds.length > 0 && (
                    <AddStaffTerminal terminalIds={selectedTerminalIds} />
                )}
                <div>
                    {allTerminals.length === 0 ? (
                        <div className="flex flex-col items-center justify-center mt-14">
                            <div className="items-center justify-center w-20 h-16 mb-4">
                                <img src={PosIcon} alt="" />
                            </div>
                            <div>
                                <p className="w-64 h-20 text-sm font-normal text-center font-avenirBook">
                                    You don&apos;t have any active POS Terminal
                                    assigned to your community. Request a new
                                    terminal to get started
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="terminal-table">
                            <DataTable
                                data={allTerminals}
                                columns={columns}
                                highlightOnHover
                                responsive
                                pointerOnHover
                                subHeader
                                subHeaderWrap
                                progressPending={loading}
                                progressComponent={<Loading />}
                                // onRowDoubleClicked={handleRowSelected}
                                onRowClicked={handleRowSelected}
                                pagination
                            />
                            <div className="flex flex-col items-center mt-2 text-xs text-white">
                                <button
                                    disabled={loading}
                                    type="button"
                                    className="px-6 py-4 bg-neutral-700 rounded-full w-fit flex gap-2 items-center"
                                    onClick={onLoadMore}
                                >
                                    {loading && <CustomLoader size="base" />}
                                    Load More
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {selection && (
                <Fragment key="selection--fragment">
                    {selection.action === "details" && (
                        <TerminalDetails
                            open
                            toggleModal={handleSelectionClose}
                            terminalID={selection.data.terminalId}
                        />
                    )}
                    {selection.action === "re-assign" && (
                        <ReAssignTerminal
                            row={selection.data}
                            handleClose={handleSelectionClose}
                        />
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default DefaultingTerminals;
