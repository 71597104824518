import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button } from "rsuite";
import PageHelmet from "../../../components/blocks/PageHelmet";
import TableSubHeader from "../../../components/blocks/TableSubHeader";
import Loading from "../../../components/elements/Loading/Loading";
import { getAllCustomerApi } from "../../../redux/slice/customers/customers.api";
import formatDate from "../../../utils/formatDate";
import AddCustomer from "./AddCustomer";
import CustomerDetail from "./CustomerDetail";
import FilterCustomers from "./FilterCustomers";
import PaginationFilter from "../../../components/elements/paginationFilter";
import CustomLoader from "../../../components/elements/Loading/CustomLoader";

const columns = [
    {
        name: "Fullname",
        selector: (row: any) => `${row.first_name} ${row.last_name}`,
        grow: 1,
    },
    {
        name: "Phone Number",
        selector: (row: any) => row.phone_number,
    },
    {
        name: "BVN",
        selector: (row: any) => row.bvn,
    },
    {
        name: "PIN",
        selector: (row: any) => row.nin,
    },
    {
        name: "Date Created",
        selector: (row: any) => formatDate(row.created_at),
    },
];

const Customers = () => {
    const [customers, setCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [queryObject, setQueryObject] = useState({});
    const [showFilter, setShowFilter] = useState(false);
    const [pagination, setPagination] = useState({
        perPage: 5000,
        page: 1,
    });
    const [showCreate, setShowCreate] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [maxRow, setMaxRow] = useState(20);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedRows, setSelectedRows] = useState([]); // State to store selected rows

    const fetchCustomers = async (pageLimit?: number, query?: any) => {
        setLoading(true);
        try {
            const res = await getAllCustomerApi(
                pageLimit || pagination.perPage,
                pagination.page,
                query
            );
            setCustomers(res.customers);
            setLoading(false);
            return res;
        } catch (error) {
            setLoading(false);
            return null;
        }
    };

    const handleHideCustomerDetail = () => {
        setShowDetail(false);
    };

    const handleRefresh = () => {
        if (Object.entries(queryObject).length === 0) {
            fetchCustomers(pagination.perPage);
            setQueryObject({});
        } else {
            const res = Object.entries(queryObject).filter(e => e[1] !== "");
            fetchCustomers(pagination.perPage, Object.fromEntries(res));
            setQueryObject(Object.fromEntries(res));
        }
    };

    const handleFilterChoice = () => {
        setShowFilter(true);
    };

    const handleShowCreate = () => {
        setShowCreate(true);
    };

    const handleCloseCreate = () => {
        setShowCreate(false);
        fetchCustomers();
    };

    const handleShowCustomerDetail = (row: any) => {
        setSelectedCustomer({
            id: row.userId,
            first_name: row.first_name,
            last_name: row.last_name,
            phone_number: row.phone_number,
            walletRecords: row.loa_walletRecord,
            posAgentRecords: row.loa_posAgentRecords,
            bvn: row.bvn,
            nin: row.nin,
            easy_savings_account_number:
                row.easy_savings_account_number === ""
                    ? "Nil"
                    : row.easy_savings_account_number,
            loa_autoDebittingEnabled: row.loa_autoDebittingEnabled,
            loa_dailySavingsAmount: row.loa_dailySavingsAmount,
            loa_userCommunitySuccessPhase: row.loa_userCommunitySuccessPhase,
            created_at: row.created_at,
        });
        setShowDetail(true);
    };

    const filterCustomer = (query: any) => {
        setShowFilter(false);

        if (Object.entries(query).length === 0) {
            fetchCustomers(pagination.perPage);
            setQueryObject({});
        } else {
            const res = Object.entries(query).filter(e => e[1] !== "");
            fetchCustomers(pagination.perPage, Object.fromEntries(res));
            setQueryObject(Object.fromEntries(res));
        }
    };

    const getPageData = (current: number, paginationLength: number) => {
        const startIndex = (current - 1) * paginationLength;
        const endIndex = startIndex + paginationLength;

        return customers.slice(startIndex, endIndex);
    };

    const handlePagination = (value: number) => {
        setCurrentPage(value);

        const pageData = getPageData(value, maxRow);
        setFilteredCustomers(pageData);
    };

    function filterDataByDateRange(data: any, startDate: any, endDate: any) {
        return data.filter(
            (item: any) =>
                new Date(item.created_at.toString().split("T")[0]).getTime() >=
                    new Date(startDate).getTime() &&
                new Date(item.created_at.toString().split("T")[0]).getTime() <=
                    new Date(endDate).getTime()
        );
    }

    const handleDateFilter = async (start: any, end: any) => {
        await fetchCustomers().then(res => {
            const data = filterDataByDateRange(res.customers, start, end);
            setCustomers(data);
        });

        // Reset Pagination
        setCurrentPage(1);
        handlePagination(currentPage);
    };

    const onNext = async () => {
        const newPage = pagination.perPage + pagination.perPage;
        await fetchCustomers(newPage, queryObject);
        setPagination({ ...pagination, perPage: newPage });
    };

    const handleRowSelected = (state: any) => {
        setSelectedRows(state.selectedRows); // Update the selected rows state
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    useEffect(() => {
        handlePagination(currentPage);
    }, [customers]);

    return (
        <>
            <PageHelmet title="Customers" />
            <div className="flex items-center justify-between w-full p-0 mb-8">
                <h1 className="font-bold text-base">Customer Management</h1>
                <div>
                    <Button
                        className="bg-[#006399] py-1.5 px-4 text-white hover:bg-[#0073b3] hover:text-white border-0 text-sm"
                        onClick={handleShowCreate}
                    >
                        Create Customer
                    </Button>
                </div>
            </div>

            <div className="w-full min-h-[50vh] overflow-y-auto">
                <DataTable
                    columns={columns}
                    data={filteredCustomers}
                    highlightOnHover
                    responsive
                    pointerOnHover
                    selectableRows // Enable row selection
                    onSelectedRowsChange={handleRowSelected} // Handle row selection change
                    subHeader
                    subHeaderComponent={
                        <TableSubHeader
                            handleDateChange={handleDateFilter}
                            handleFilter={handleFilterChoice}
                            queryObject={queryObject}
                            filterTransaction={filterCustomer}
                            handleRefresh={handleRefresh}
                        />
                    }
                    subHeaderWrap
                    progressPending={loading}
                    progressComponent={<Loading />}
                    onRowClicked={handleShowCustomerDetail}
                />
            </div>

            <div className="flex flex-col">
                {!loading && (
                    <PaginationFilter
                        currentPage={currentPage}
                        totalPages={Math.ceil(customers.length / maxRow)}
                        onPageChange={handlePagination}
                    />
                )}
            </div>
            <div className="flex flex-col items-center mt-2 text-xs text-white">
                <button
                    disabled={loading}
                    type="button"
                    className="px-6 py-4 bg-neutral-700 rounded-full w-fit flex gap-2 items-center"
                    onClick={onNext}
                >
                    {loading && <CustomLoader size="base" />}
                    Load More
                </button>
            </div>
            {showFilter && (
                <FilterCustomers
                    open
                    handleClose={() => setShowFilter(false)}
                    handleFilter={filterCustomer}
                />
            )}
            {showCreate && (
                <AddCustomer open handleClose={() => handleCloseCreate()} />
            )}
            {showDetail && (
                <CustomerDetail
                    open
                    toggleModal={handleHideCustomerDetail}
                    customer={selectedCustomer}
                />
            )}
        </>
    );
};

export default Customers;
