import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";

interface DailyTransaction {
    transactionDate: string;
    transactionCount: number;
    _id: string;
}

interface DailyTransactionsProps {
    dailyTransactions: DailyTransaction[];
}

const DailyTransactions: React.FC<DailyTransactionsProps> = ({
    dailyTransactions,
}) => {
    const columns: TableColumn<DailyTransaction>[] = [
        {
            name: "Date",
            selector: row => new Date(row.transactionDate).toLocaleDateString(),
            sortable: true,
        },
        {
            name: "Transaction Count",
            selector: row => row.transactionCount,
            sortable: true,
        },
    ];

    return (
        <div className="bg-white shadow-lg rounded-lg p-6 my-6">
            <h4 className="text-xl font-semibold mb-4">Daily Transactions</h4>
            <DataTable
                columns={columns}
                data={dailyTransactions}
                highlightOnHover
                responsive
                pagination
            />
        </div>
    );
};

export default DailyTransactions;
