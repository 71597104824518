/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { Edit } from "lucide-react";
import { Button, Loader, Modal } from "rsuite";
import { z } from "zod";
import { useNavigate } from "react-router";
import { SuccessToast, ErrorToast } from "../../../components/blocks/Alert";
import { updateBucketApi } from "../../../redux/slice/monitor/monitor.api";

// Define the schema for the bucket name
const BucketNameSchema = z.string().min(1, {
    message: "Bucket name cannot be empty.",
});

type Props = {
    bucketId: string | undefined;
    bucketName: string;
};

const EditBucket = ({ bucketId, bucketName: prevName }: Props) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [bucketName, setBucketName] = useState<string>(prevName);
    const [error, setError] = useState<string | null>(null);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBucketName(event.target.value);
        setError(null); // Clear any previous errors
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!bucketId) return;

        try {
            setLoading(true);
            // Validate the bucket name against the schema
            BucketNameSchema.parse(bucketName);

            // Call the update bucket API
            await updateBucketApi(bucketId, { bucketName });

            // Show success message
            SuccessToast("Bucket name updated successfully!");

            navigate(`/monitor`);
            // Close the modal
            setOpen(false);
        } catch (err) {
            if (err instanceof z.ZodError) {
                setError(err.errors[0].message);
            } else {
                ErrorToast("An error occurred while updating the bucket name.");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                appearance="primary"
                className="flex items-center h-fit bg-indigo-400 hover:bg-indigo-500"
            >
                <Edit className="mr-2 h-4 w-4" /> Edit Bucket
            </Button>
            <Modal open={open} onClose={() => setOpen(false)} size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Bucket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit}
                        className="space-y-4 flex flex-col"
                    >
                        <div>
                            <label
                                htmlFor="bucketName"
                                className="block font-medium mb-1"
                            >
                                Bucket Name
                            </label>
                            <input
                                id="bucketName"
                                name="bucketName"
                                type="text"
                                value={bucketName}
                                onChange={handleNameChange}
                                className="w-full border border-gray-300 rounded p-2"
                            />
                            {error && (
                                <p className="text-red-500 text-sm mt-1">
                                    {error}
                                </p>
                            )}
                        </div>

                        <button
                            type="submit"
                            className="bg-blue-500 min-w-[160px] text-white px-4 py-2 rounded ml-auto"
                            disabled={loading}
                        >
                            {loading ? <Loader /> : "Update Bucket"}
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditBucket;
