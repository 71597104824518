/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import { Tag } from "@rsuite/icons";
import AssignTagsModal from "./AssignTagsModal";

interface Props {
    bucketUserWalletKeys: string[];
    bucketId: string | undefined;
    handleModalClose: () => void;
}

const Tags = ({ bucketUserWalletKeys, bucketId, handleModalClose }: Props) => {
    const [addTag, setAddTag] = useState(false); // Updated state name

    const [open, setOpen] = useState(false);
    // const [logData, setLogData] = useState<any[]>([]);

    const [props, setProps] = useState<any>([]);

    const handleClose = () => {
        setAddTag(false);
        setOpen(false);
        handleModalClose();
    };

    useEffect(() => {
        // get the accountReference of the selectedRows
        const sub = {
            bucketUserWalletKeys,
            bucketId,
        };
        setProps(sub);
    }, [bucketUserWalletKeys]);

    return (
        <>
            <Button
                appearance="primary"
                className="bg-[#006399] py-2 px-4 text-white w-full max-w-[180px] hover:bg-[#0073b3] rounded"
                onClick={() => {
                    setAddTag(true);
                    setOpen(true);
                }} // Updated state function
            >
                <Tag className="mx-2" />
                Assign to Tag
                <span className="mx-2">{bucketUserWalletKeys.length}</span>
            </Button>

            {addTag && ( // Updated state reference
                <AssignTagsModal
                    // tags={tags} // Updated prop name
                    open={open}
                    props={props}
                    handleClose={handleClose} // Updated state function
                />
            )}
        </>
    );
};

export default Tags;
