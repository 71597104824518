/* eslint-disable react/react-in-jsx-scope */
import { useState } from "react";
import { useNavigate } from "react-router";
import { SelectPicker } from "rsuite";
import { ErrorToast } from "../../../components/blocks/Alert";
import { CustomButton } from "../../../components/elements/Button";
import Loading from "../../../components/elements/Loading/Loading";
import {
    useGetAllDefaultingTerminalsQuery,
    useGetAllRequestedTerminalsQuery,
    useGetAllTerminalsQuery,
} from "../../../redux/slice/terminals";
import AllTerminals from "./AllTerminals";
import ApprovedTerminals from "./ApprovedTerminals";
import CreateTerminal from "./CreateTerminal";
import TerminalRequested from "./TerminalRequested";
import DefaultingTerminals from "./DefaultingTerminals";

const PosTerminals = () => {
    const [action, setAction] = useState<Record<string, boolean>>({});
    const [toggle, setToggle] = useState("defaultingTerminals");

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pagination, setPagination] = useState({
        perPage: 100,
        page: 1,
    });

    const {
        data: allTerminals,
        isLoading: aLoading,
        isFetching,
        refetch: refetchAllTerminals,
    } = useGetAllTerminalsQuery();
    const {
        data: allDefaultingTerminals,
        isLoading: bLoading,
        isFetching: isBFetching,
        refetch: refetchAllDefaultingTerminals,
    } = useGetAllDefaultingTerminalsQuery({
        page: pagination.page,
        limit: pagination.perPage,
    });
    const { data: requestedTerminal, refetch: refetchAllRequests } =
        useGetAllRequestedTerminalsQuery();

    const loading = aLoading || isFetching || isBFetching || bLoading || false;

    const navigate = useNavigate();

    const terminalStatus = [
        { label: "Active", value: "active" },
        { label: "Suspended", value: "suspended" },
        { label: "Disabled", value: "disabled" },
    ];

    const updateToggle = (id: any) => {
        setToggle(id);
    };

    const activateAction = (actionName: string) => {
        setAction({
            [actionName]: true,
        });
    };

    const deactivateAction = () => {
        setAction({});
    };

    const handleRefetch = () => {
        try {
            refetchAllRequests();
            refetchAllTerminals();
            refetchAllDefaultingTerminals();
        } catch (error) {
            ErrorToast(error);
        }
    };

    const onNext = async () => {
        const newPage = pagination.perPage + pagination.perPage;
        setPagination({ ...pagination, perPage: newPage });
        await refetchAllDefaultingTerminals();
    };

    return (
        <div>
            {loading ? (
                <div className="flex items-center justify-center">
                    <Loading />
                </div>
            ) : (
                <div>
                    <ApprovedTerminals
                        loading={loading}
                        value={allTerminals?.data.length ?? 0}
                    />
                    <div className="flex flex-wrap justify-between gap-4 sm:flex-row">
                        <div className="space-y-2">
                            <h3 className="text-xl font-extrabold font-avenirBook text-[#000000] sm:text-base">
                                Terminals
                            </h3>
                            <p className="text-sm font-avenirBook text-[#667085]">
                                Manage all terminals and its assigned admin user
                            </p>
                        </div>

                        <div>
                            <CustomButton
                                className="h-10 w-36 font-avenirBook font-extrabold rounded bg-[#995C12] text-[white] hover:bg-[#995C12] hover:text-[white]"
                                onClick={() => activateAction("create")}
                            >
                                Create Terminal
                            </CustomButton>
                        </div>
                    </div>

                    <div className="flex flex-wrap-reverse gap-4 mt-10 justify-between sm:space-x-4 sm:flex-row">
                        <div className="flex gap-3">
                            <span
                                className={
                                    toggle === "defaultingTerminals"
                                        ? `border-b-2 border-b-[#F75500] font-avenirBook font-extrabold text-[#101828] cursor-pointer`
                                        : `text-[#667085]`
                                }
                                onClick={() =>
                                    updateToggle("defaultingTerminals")
                                }
                                role="button"
                            >
                                Defaulting Terminals
                            </span>
                            <span
                                className={
                                    toggle === "allTerminals"
                                        ? `border-b-2 border-b-[#F75500] font-avenirBook font-extrabold text-[#101828] cursor-pointer`
                                        : `text-[#667085]`
                                }
                                onClick={() => updateToggle("allTerminals")}
                                role="button"
                            >
                                All Terminals
                            </span>
                            <span
                                className={
                                    toggle === "terminalRequested"
                                        ? `border-b-2 border-b-[#F75500] font-avenirBook font-extrabold text-[#101828] cursor-pointer`
                                        : `text-[#667085]`
                                }
                                onClick={() =>
                                    updateToggle("terminalRequested")
                                }
                                role="button"
                            >
                                Terminal Requested
                            </span>
                        </div>
                        <div>
                            <SelectPicker
                                data={terminalStatus}
                                style={{ width: 150 }}
                                placeholder="Status"
                                searchable={false}
                                className="mt-8"
                            />
                        </div>
                    </div>

                    {toggle === "defaultingTerminals" && (
                        <DefaultingTerminals
                            allTerminals={allDefaultingTerminals?.data ?? []}
                            loading={bLoading}
                            onTerminalDetailsClick={row =>
                                navigate(
                                    `/pos-terminals/reach-out/${row.terminalId}`
                                )
                            }
                            onLoadMore={onNext}
                        />
                    )}
                    {toggle === "allTerminals" && (
                        <AllTerminals
                            allTerminals={allTerminals?.data ?? []}
                            loading={loading}
                            onTerminalDetailsClick={row =>
                                navigate(row.terminalId)
                            }
                        />
                    )}
                    {toggle === "terminalRequested" && (
                        <TerminalRequested
                            terminalRequested={
                                requestedTerminal?.data.requests ?? []
                            }
                            loading={loading}
                            refetch={handleRefetch}
                        />
                    )}
                </div>
            )}
            {action.create && <CreateTerminal toggleModal={deactivateAction} />}
        </div>
    );
};

export default PosTerminals;
