export interface iRole {
    name: string; // Ensure 'name' is always present
    [key: string]: string | number; // Allow any key-value pairs where the value is string or number
    // value: string; // Ensure 'value' is always present
}

export const roleOptions: iRole[] = [
    { name: "Guest Support", value: "GUEST_SUPPORT" },
    { name: "Customer Support", value: "CS" },
    { name: "Frontline Sales Officer", value: "FSO" },
    { name: "State Manager", value: "STATE_MANAGER" },
    { name: "HQ Employee", value: "HQ_EMPLOYEE" },
    { name: "HQ Manager", value: "HQ_MANAGER" },
];
