/* eslint-disable import/prefer-default-export */
import axios from "../../../axios";

export const getActivitiesApi = async () => {
    try {
        const url = `/activities`;
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getBucketsApi = async () => {
    try {
        const url = `/buckets`;
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const createBucketApi = async (payload: any) => {
    try {
        const url = "/buckets";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const updateBucketApi = async (bucketId: string, payload: any) => {
    try {
        const url = `/buckets/${bucketId}`;
        const res = await axios.put(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getBucketDetailsApi = async (bucketId: string) => {
    try {
        const url = `/buckets/${bucketId}/activities/users`;
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

// Define the function to add users to a bucket
export const dynamicUserFilterApi = async (payload: any) => {
    try {
        const url = `tags/filter`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Define the function to add users to a bucket
export const addUsersToBucketApi = async (
    bucketId: string,
    phoneNumbers: string[]
) => {
    try {
        const url = `buckets/users`;
        const response = await axios.post(url, {
            bucketId,
            phoneNumbers,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// New method for bulk uploading users using a CSV file
export const bulkUploadUsersToBucketApi = async (
    bucketId: string,
    file: File
) => {
    try {
        const url = `buckets/users/bulk-upload`; // Updated URL for bulk upload
        const formData = new FormData(); // Create a FormData object

        // Append the bucketId and file to the FormData
        formData.append("bucketId", bucketId);
        formData.append("file", file);

        // Make the POST request with the FormData
        const response = await axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data", // Set the content type for file upload
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};
