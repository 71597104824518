/* eslint-disable react/react-in-jsx-scope */
import { useId, useState } from "react";
import { CustomButton } from "../../../components/elements/Button";
import DrawerPanel from "../../../components/elements/DrawerPanel/DrawerPanel";
import formatWord from "../../../utils/formatWord";

import { SuccessToast } from "../../../components/elements/Alerts";
import Loading from "../../../components/elements/Loading/Loading";
import { reversePendingTransactionsApi } from "../../../redux/slice/transactions/transactions.api";
import CustomerDetail from "./filterModals/CustomerDetail";

type Props = {
    open: boolean;
    toggleModal: () => void;
    details: any;
    userRecord: any;
};

const Row = ({ title, value }: { title: string; value: any }) => (
    <div className="flex items-start gap-3 justify-between py-4 text-xs border-b border-black border-dotted sm:text-sm">
        <div className="text-left">{title}:</div>
        <div className="text-right font-semibold">{value}</div>
    </div>
);

const TransactionDetail = ({
    open,
    toggleModal,
    details,
    userRecord,
}: Props) => {
    const id = useId();
    const [showDetail, setShowDetail] = useState(false);
    const [loading, setLoading] = useState({
        reverse: false,
    });

    const handleShowDetail = () => {
        setShowDetail(true);
    };

    const closeDetail = () => {
        setShowDetail(false);
    };

    const isEmpty = Object.keys(userRecord).length === 0;

    const handleReversePendingTransaction = async () => {
        setLoading({
            reverse: true,
        });
        try {
            const res = await reversePendingTransactionsApi();
            SuccessToast(res.message);
            setLoading({
                reverse: true,
            });
        } catch (err) {
            setLoading({
                reverse: false,
            });
        }
    };

    return (
        <>
            <DrawerPanel
                open={open}
                handleClose={toggleModal}
                title="Transaction Detail"
            >
                <div className="px-4 text-center font-avenirBook">
                    <div className="py-4 mb-2 text-xs bg-white">
                        {Object.entries(details).map(d => (
                            <Row
                                title={formatWord(d[0])}
                                value={d[1]}
                                key={id + d[0]}
                            />
                        ))}
                    </div>
                    {details.status === "pending" && (
                        <div className="mt-8 ml-auto text-white border w-fit">
                            <CustomButton
                                className="w-40 h-10 rounded bg-slate-600"
                                onClick={() =>
                                    handleReversePendingTransaction()
                                }
                            >
                                {loading.reverse ? (
                                    <Loading size="xs" />
                                ) : (
                                    "Reverse Transaction"
                                )}
                            </CustomButton>
                        </div>
                    )}

                    {!isEmpty && (
                        <CustomButton
                            buttonStyle="bg-[#0B4665] text-white mt-4 px-10 hover:bg-[#001622]"
                            onClick={handleShowDetail}
                        >
                            View Customer Detail
                        </CustomButton>
                    )}
                </div>
            </DrawerPanel>

            {showDetail && (
                <CustomerDetail
                    open={showDetail}
                    handleClose={closeDetail}
                    user={userRecord}
                />
            )}
        </>
    );
};

export default TransactionDetail;
