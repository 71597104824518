import axios from "../../../axios";
import { getCookie } from "../../../utils/cookiesHandler";

// import { getCookie } from "../utils/cookiesHandler";

// const token = getCookie("act");

export const getAllReminderApi = async (query?: any) => {
    try {
        let url = `lolastaffs/reminders`;
        if (query) {
            Object.keys(query).forEach(key => {
                url += `&${key}=${query[key]}`;
            });
        }
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const createStaffReminderApi = async (data: any) => {
    try {
        const { assignedTo, ...restData } = data;
        const url = "lolastaffs/reminders";
        const userId = assignedTo;
        const payload = { ...restData, userId };
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};
export const createReminderApi = async (data: any) => {
    try {
        const url = "lolastaffs/reminders";
        const userId = getCookie("userId");
        const payload = { ...data, userId };
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const assignReminderApi = async (payload: any) => {
    try {
        const url = "lolastaffs/reminders/assign";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const deAssignReminderApi = async (payload: any) => {
    try {
        const url = "lolastaffs/reminders/deassign";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const deleteReminderApi = async (reminderId: string) => {
    try {
        const url = `lolastaffs/reminders/${reminderId}`;
        const res = await axios.delete(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const updateReminderApi = async (reminderId: string, payload: any) => {
    try {
        const url = `lolastaffs/reminders/${reminderId}`;
        const res = await axios.put(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};
