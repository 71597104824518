import React from "react";
import { Button, Modal } from "rsuite";

type Props = {
    open: boolean;
    handleClose: () => void;
    user: any;
};

const Row = ({ title, value }: { title: string; value: any }) => (
    <div className="flex items-center justify-between py-2 text-xs sm:text-sm">
        <div className="text-left">{title}:</div>
        <div className="font-semibold tracking-wider text-right">{value}</div>
    </div>
);

const CustomerDetail = ({ open, handleClose, user }: Props) => {
    const { userEasySavingsRecord, userWallet, userRecord } = user;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Customer Detail
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-4 mb-4 border rounded-xl">
                    <h3 className="font-bold">Easy Savings </h3>
                    <Row
                        title="Easy Savings Balance"
                        value={userEasySavingsRecord.easySavingsBalance}
                    />
                </div>
                <div className="px-4 mb-4 border rounded-xl">
                    <h3 className="font-bold">Wallet</h3>
                    <Row
                        title="Account Number"
                        value={userWallet.virtualAccountNumber}
                    />
                    <Row
                        title="Available Balance"
                        value={userWallet.avaialbleBalance}
                    />
                    <Row
                        title="Bonus Balance"
                        value={userWallet.bonusBalance}
                    />
                </div>
                <div className="p-4 border rounded-xl">
                    <h3 className="font-bold">User Detail</h3>
                    <Row
                        title="Customer Name"
                        value={`${userRecord.first_name} ${userRecord.last_name}`}
                    />
                    <Row title="BVN" value={userRecord.bvn} />
                    <Row title="NIN" value={userRecord.nin} />
                </div>
            </Modal.Body>
            <div className="flex flex-col justify-center">
                <Button
                    onClick={handleClose}
                    className="text-red-600 bg-slate-100"
                >
                    Close
                </Button>
            </div>
        </Modal>
    );
};

export default CustomerDetail;
