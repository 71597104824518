/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, InputPicker, Loader } from "rsuite";
import {
    assignTagsApi,
    createTagsApi,
    getAllTagsApi,
} from "../../../redux/slice/tags/tags.api";
import { ErrorToast, SuccessToast } from "../Alert";

interface Tag {
    type: string;
    _id: string;
    label: string;
    color: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

type Props = {
    open: boolean;
    props: any;
    handleClose: () => void;
};

const AssignTagsModal = ({ open, props, handleClose }: Props) => {
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [newTag, setNewTag] = useState({
        label: "",
        color: "#FFD700",
        type: "manual",
    });

    const [tags, setTags] = useState<Tag[]>([]);
    // State to store selected tag IDs
    const [selectedTagId, setSelectedTagId] = useState<string | null>(null);

    const [loading, setLoading] = useState(false);
    const [assignLoading, setAssignLoading] = useState<boolean>(false);
    const [fetchLoading, setFetchLoading] = useState<boolean>(true);

    // Function to handle tag selection
    const handleTagSelection = (tagId: string) => {
        setSelectedTagId((prevSelected: any) => {
            // If the clicked tag is already selected, deselect it
            if (prevSelected === tagId) {
                return null;
            }
            // Otherwise, select the new tag
            return tagId;
        });
    };

    const fetchTags = async () => {
        try {
            setFetchLoading(true);
            const res = await getAllTagsApi();
            console.log("Tags: ", res.data.data);
            setTags(res.data.data); // Updated state assignment
            return res;
        } catch (error) {
            return null;
        } finally {
            setFetchLoading(false);
        }
    };

    const handleAssignTag = async () => {
        try {
            setAssignLoading(true);

            // Handle the creation of the new tag (e.g., API call)

            await assignTagsApi({ ...props, tagId: selectedTagId });

            SuccessToast("Tag Assigned successfully!");
            handleClose();
        } catch (err) {
            ErrorToast("An error occurred while assigning to tag.");
        } finally {
            setAssignLoading(false);
        }
    };

    const handleCreateTag = async () => {
        // event.preventDefault();

        try {
            setLoading(true);

            // Handle the creation of the new tag (e.g., API call)
            console.log("New Tag Data:", newTag);

            await createTagsApi(newTag);

            // Show success message
            SuccessToast("New Tag Created successfully!");

            // Reset the form and hide it
            setNewTag({
                label: "",
                color: "#FFD700",
                type: "manual",
            });
            setShowCreateForm(false);
            fetchTags();
        } catch (err) {
            ErrorToast("An error occurred while creating the tag.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTags(); // Updated function call
    }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="sm"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">Tags List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="flex flex-row flex-wrap w-full gap-2 pb-6 border-b">
                    {fetchLoading ? ( // Show skeleton loading if fetchLoading is true
                        [...Array(3)].map((_, index) => (
                            <div
                                key={index}
                                className="p-2 min-w-[160px] max-w-xs border rounded-lg bg-white border-gray-200 flex flex-row gap-2 animate-pulse"
                            >
                                <div className="w-5 h-5 bg-gray-200 rounded-full" />{" "}
                                {/* Skeleton for color */}
                                <div className="flex flex-col flex-1">
                                    <div className="h-4 bg-gray-200 rounded w-3/4 mb-1" />{" "}
                                    {/* Skeleton for label */}
                                </div>
                            </div>
                        ))
                    ) : tags.length === 0 ? ( // Show "No tags yet" message if tags array is empty
                        <p className="text-center text-gray-500 bg-slate-50 rounded p-2 w-full">
                            No tags yet.
                        </p>
                    ) : (
                        // Render actual tags when fetchLoading is false and tags array is not empty
                        tags
                            .filter(tag => tag.type !== "dynamic") // Filter out tags with type "dynamic"
                            .map(tag => (
                                <div
                                    role="button"
                                    key={tag._id}
                                    className={`p-2 min-w-[160px] max-w-xs border rounded-lg bg-white border-gray-200 flex flex-row gap-2 cursor-pointer ${
                                        selectedTagId === tag._id
                                            ? "border-blue-500 bg-blue-50" // Visual feedback for selected tags
                                            : ""
                                    }`}
                                    onClick={() => handleTagSelection(tag._id)} // Handle selection on click
                                >
                                    <div
                                        className="w-5 h-5 rounded-full"
                                        style={{ backgroundColor: tag.color }} // Use inline style for dynamic color
                                    />
                                    <div className="flex flex-col">
                                        <p className="text-sm font-medium">
                                            {tag.label}
                                        </p>
                                    </div>
                                </div>
                            ))
                    )}
                    {selectedTagId !== null && (
                        <Button
                            type="button"
                            appearance="default"
                            onClick={handleAssignTag}
                            className="w-full !bg-indigo-400 hover:!bg-indigo-500 !text-white"
                        >
                            {assignLoading ? <Loader /> : "Assign to Selected"}
                        </Button>
                    )}
                </div>

                {/* Create Tag Button */}
                <div className="mt-8 flex flex-col">
                    <Button
                        appearance="primary"
                        onClick={() => setShowCreateForm(!showCreateForm)}
                        className={`${
                            !showCreateForm
                                ? "!bg-[#006399] hover:!bg-[#0073b3]"
                                : "!bg-red-400 hover:!bg-red-500"
                        } ml-auto py-2 px-4 text-white w-full max-w-[180px] rounded`}
                    >
                        {showCreateForm ? "Cancel" : "Create New Tag"}
                    </Button>
                </div>

                {/* Create Tag Form */}
                {showCreateForm && (
                    <Form fluid className="mt-4" onSubmit={handleCreateTag}>
                        <Form.Group>
                            <Form.ControlLabel>Label</Form.ControlLabel>
                            <Form.Control
                                name="label"
                                value={newTag.label}
                                onChange={(value: any) =>
                                    setNewTag({ ...newTag, label: value })
                                }
                                placeholder="Enter tag label"
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Color</Form.ControlLabel>
                            <Input
                                type="color"
                                value={newTag.color}
                                onChange={value =>
                                    setNewTag({ ...newTag, color: value })
                                }
                                className="h-8"
                                required
                            />
                        </Form.Group>
                        <Button
                            type="submit"
                            appearance="default"
                            className="w-full mt-4 !bg-slate-700 hover:!bg-slate-800 !text-white"
                        >
                            {loading ? <Loader /> : "Create New Tag"}
                        </Button>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AssignTagsModal;
