import React from "react";
import { useRoutes } from "react-router";
import loadable from "@loadable/component";

import AuthGuard from "../components/hoc/AuthGuard";
import NotFound from "../pages/NotFound";
import Signout from "../pages/appcore/Signout";
import MainWrapper from "../components/layout/MainWrapper/index";
import Users from "../pages/appcore/customers/Customers";
import Communities from "../pages/appcore/communities/Communities";
import Staff from "../pages/appcore/staff/Staff";
import PosAgents from "../pages/appcore/posagents";
import Reports from "../pages/appcore/reports";
import Workspaces from "../pages/appcore/workspaces";
import CommunityReports from "../pages/appcore/reports/CommunityReports";
import Teams from "../pages/appcore/teams/Teams";
import PosTerminals from "../pages/appcore/posterminals";
import TerminalTransactions from "../pages/appcore/posterminals/TerminalTransactions";
import MonitorPage from "../pages/appcore/monitor";
import ActivityPage from "../pages/appcore/monitor/Activities";
import Transactions from "../components/blocks/Transactions";
import DefaultingTerminalTransactions from "../pages/appcore/posterminals/DefaultingTerminalTransactions";
// import MonitoringPage from "../pages/appcore/monitor";

const Signin = loadable(() => import("../pages/auth/Signin"));
const Dashboard = loadable(
    () => import("../pages/appcore/dashboard/Dashboard")
);

const AppRoutes = () => {
    const allRoutes = useRoutes([
        {
            path: "/",
            children: [
                { element: <Signin />, path: "/", index: true },
                { path: "/signin", element: <Signin /> },
                {
                    path: "/",
                    element: <MainWrapper />,
                    children: [
                        { element: <Reports />, path: "reports" },
                        {
                            element: <CommunityReports />,
                            path: "/community-report",
                        },
                    ],
                },
            ],
        },
        {
            path: "/",
            element: <AuthGuard />,
            children: [
                {
                    path: "/",
                    element: <MainWrapper />,
                    children: [
                        { element: <Dashboard />, path: "dashboard" },
                        { element: <Users />, path: "users" },
                        { element: <Transactions />, path: "transactions" },
                        { element: <Communities />, path: "communities" },
                        { element: <Staff />, path: "staff" },
                        { element: <PosAgents />, path: "pos-agents" },
                        { element: <Workspaces />, path: "workspaces" },
                        {
                            path: "monitor",
                            children: [
                                { element: <MonitorPage />, index: true },
                                {
                                    element: <ActivityPage />,
                                    path: "activities/:bucketId",
                                },
                            ],
                        },
                        { element: <Teams />, path: "teams" },
                        {
                            path: "pos-terminals",
                            children: [
                                { element: <PosTerminals />, index: true },
                                {
                                    element: <TerminalTransactions />,
                                    path: ":id",
                                },
                                {
                                    element: <DefaultingTerminalTransactions />,
                                    path: "reach-out/:id",
                                },
                            ],
                        },

                        // { element: <Reports />, path: "reports" },
                    ],
                },
            ],
        },
        { path: "*", element: <NotFound /> },
        { path: "/logout", element: <Signout /> },
    ]);

    return allRoutes;
};

export default AppRoutes;
