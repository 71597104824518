import { ArrowLeftLine } from "@rsuite/icons";
import React, { useState } from "react";
import store from "store";
import { FaAngleDown, FaPowerOff, FaAngleUp } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import LolaImgMin from "../../../assets/images/logo/lola-logo-min.png";
import { guestNav, staffNav } from "./data";

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);

    const handleSignout = () => {
        store.clearAll();
        navigate("/signin");
    };

    const staffRecord = store.get("staffRecord");
    const menuNav =
        staffRecord?.operationsStaffLevel === "GUEST_SUPPORT"
            ? guestNav
            : staffNav;

    const handleSubMenuToggle = (itemClass?: string) => {
        setOpenSubMenu(prev => (prev === itemClass ? null : itemClass || null));
    };

    const handleSidebarToggle = () => {
        setOpen(!open);
    };

    return (
        <aside className="relative z-40 block h-screen sm:z-30 sm:block sm:relative transition-all border-r border-gray-200">
            <div className="bg-[#F9FAFB] px-2 py-5 sm:p-3 duration-700 transition-all h-full flex flex-col gap-6 sticky z-30">
                <ArrowLeftLine
                    className={`absolute -right-3 top-16  border-2 transition-transform duration-500 z-10 p-1 font-semibold bg-[#F9FAFB] rounded-full shadow cursor-pointer w-8 h-8 ${
                        !open && "rotate-180"
                    }`}
                    style={{ fontSize: "1.5rem" }}
                    onClick={handleSidebarToggle}
                />
                <div className="flex items-center">
                    <img
                        src={LolaImgMin}
                        alt="alt"
                        className={`duration-1000 cursor-pointer ${
                            open && "rotate-[90deg]"
                        }`}
                    />
                </div>
                <ul className="grow flex flex-col justify-between gap-1 duration-1000">
                    {menuNav.map(item => (
                        <div key={item.name} className="flex flex-col gap-1">
                            {item.subItems ? (
                                <li className="flex flex-col">
                                    <button
                                        type="button"
                                        aria-controls="sidebar-dropdown"
                                        aria-expanded={
                                            openSubMenu === item.category
                                        }
                                        className="flex items-center text-[#667085] w-full hover:bg-[#006399] hover:border-transparent rounded-md hover:text-[#ffffff] font-semibold mb-2"
                                        onClick={() =>
                                            handleSubMenuToggle(item.category)
                                        }
                                    >
                                        <div className="flex items-center justify-start">
                                            <div className="text-sm sm:text-2xl hover:text-[#ffffff] p-3 flex">
                                                {item.icon}
                                            </div>
                                            <div
                                                className={`grid transition-all duration-600 whitespace-nowrap ${
                                                    !open
                                                        ? "grid-cols-[0fr]"
                                                        : "grid-cols-[1fr]"
                                                }`}
                                            >
                                                <div className="overflow-hidden flex items-center gap-2">
                                                    <span>{item.name}</span>
                                                    <span className="ml-auto">
                                                        {openSubMenu ===
                                                        item.category ? (
                                                            <FaAngleUp />
                                                        ) : (
                                                            <FaAngleDown />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                    <ul
                                        id="dropdown-example"
                                        className={`text-xs grid transition-all ${
                                            item.category
                                        } ${
                                            openSubMenu === item.category
                                                ? "grid-rows-[1fr]"
                                                : "grid-rows-[0fr]"
                                        }`}
                                    >
                                        <li className="overflow-hidden pl-4">
                                            {item.subItems?.map(subItem => (
                                                <Link
                                                    to={subItem.url}
                                                    onClick={() =>
                                                        setOpen(false)
                                                    }
                                                    key={subItem.id}
                                                    className={`relative flex items-center text-sm text-[#667085] bg-transparent hover:bg-gray-900 py-3 hover:text-white rounded px-2 font-semibold ${
                                                        !open ? "w-0" : "w-full"
                                                    }`}
                                                >
                                                    <span>{subItem.name}</span>
                                                </Link>
                                            ))}
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                <Link
                                    to={item.url}
                                    onClick={() => setOpen(false)}
                                    key={item.id}
                                    className={`relative flex items-center text-sm rounded-md font-semibold cursor-pointer border-0 ${
                                        location.pathname === item.url
                                            ? "bg-[#667085] !text-orange-400 hover:bg-[#667085] hover:text-orange-400"
                                            : "bg-transparent text-[#667085] hover:bg-orange-400 hover:text-[#ffffff]"
                                    }`}
                                >
                                    <div className="text-sm sm:text-2xl p-3 flex">
                                        {item.icon}
                                    </div>
                                    <div
                                        className={`grid transition-all duration-600 ${
                                            !open
                                                ? "grid-cols-[0fr]"
                                                : "grid-cols-[1fr]"
                                        } origin-left`}
                                    >
                                        <span className="overflow-hidden whitespace-nowrap font-avenirBook">
                                            {item.name}
                                        </span>
                                    </div>
                                </Link>
                            )}
                        </div>
                    ))}
                    <li className="text-sm rounded-md font-semibold cursor-pointer hover:bg-red-500 hover:text-white gap-x-4 mt-auto">
                        <div
                            onClick={handleSignout}
                            role="button"
                            className="flex items-center"
                        >
                            <div className="text-sm sm:text-xl p-3 flex">
                                <FaPowerOff className="text-inherit size-6" />
                            </div>
                            <span
                                className={`${
                                    !open && "hidden"
                                } origin-left duration-200 hover:no-underline font-avenirBook`}
                            >
                                Logout
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </aside>
    );
};

export default Sidebar;
